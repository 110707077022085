import { Box, IconButton, Typography } from '@mui/material';
import { PropsWithChildren } from 'react';

import Icon from '~/components/Icon';
import { Color } from '~/constants';

import { Card } from '../../../layouts/Card';
import { PropsWithSx } from '../../../utils';

interface HazardDetailCardProps {
  icon?: React.ReactNode;
  title?: string;
  value?: string | number | React.ReactNode;
  showDisclaimerIcon?: boolean;
  onDisclaimerIconClick?: (e: any) => void;
}

export const HazardDetailCard = ({
  icon,
  title,
  value,
  showDisclaimerIcon,
  onDisclaimerIconClick,
  children,
  sx,
}: PropsWithChildren<PropsWithSx<HazardDetailCardProps>>) => {
  return (
    <Card
      hasShadow={false}
      backgroundColor="grey"
      sx={{ display: 'flex', flexDirection: 'column', width: '100%', ...sx }}
    >
      {(icon || title || typeof value !== 'undefined') && (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            width: '100%',
            alignItems: 'center',
          }}
        >
          {icon}
          {title && (
            <Typography variant="h3" sx={{ ml: 1, textTransform: 'uppercase' }}>
              {title}
            </Typography>
          )}
          {showDisclaimerIcon && (
            <IconButton
              sx={{ padding: 0, minWidth: 0, marginLeft: 0.5 }}
              onClick={() => onDisclaimerIconClick(title)}
            >
              <Icon name="Information" size={16} color={Color.WaterBlue} />
            </IconButton>
          )}
          {typeof value !== 'undefined' && (
            <Typography sx={{ flexGrow: 1, textAlign: 'right', fontSize: 22 }}>
              {value}
            </Typography>
          )}
        </Box>
      )}
      {children && <Box sx={{ mt: 2 }}>{children}</Box>}
    </Card>
  );
};
