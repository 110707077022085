import { Box, Button, Typography, useTheme } from '@mui/material';
import { center as turfGetCenter, flip } from '@turf/turf';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMap } from 'react-map-gl';
import { useNavigate, useParams } from 'react-router-dom';

import Icon from '~/components/Icon';
import { Loader } from '~/components/UI';
import { I18n } from '~/i18n';
import { useAppDispatch } from '~/redux-rtk';
import { AppActions, LocationSelectionType } from '~/redux-rtk/slices/appSlice';
import { getNativeLanguageNames } from '~/utils/alertsUtils';

import AlertDetails from './AlertDetails';
import { sampleCardData } from '../mockData';
import { AlertCardData } from '../types';

interface SelectDataType {
  value: string;
  label: string;
}

const AlertDetailView = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const alertId: number = Number(useParams()?.id);
  const dispatch = useAppDispatch();

  const [alertDetails, setAlertDetails] = useState<AlertCardData>();
  const [availableLanguages, setAvailableLanguages] = useState<
    SelectDataType[]
  >([]);
  const [mapLoading, setMapLoading] = useState(false);

  const { mapContainer } = useMap();

  useEffect(() => {
    // data to be fetched from backend server
    const alertData = sampleCardData.find(item => item.id === alertId);
    setAlertDetails(alertData);
    const languages = getNativeLanguageNames(Object.keys(alertData?.title));
    setAvailableLanguages(
      languages.map(item => ({
        value: item.languageCode,
        label: item.displayName,
      })),
    );
  }, []);

  const addLayerToMap = () => {
    mapContainer.getMap().addSource('alert-geojson', {
      type: 'geojson',
      data: alertDetails.geom,
    });
    mapContainer.getMap().addLayer({
      id: 'alert_polygon_geom',
      type: 'fill',
      source: 'alert-geojson',
      minzoom: 0,
      maxzoom: 22,
      paint: {
        'fill-color': 'purple',
        'fill-opacity': 0.5,
        'fill-outline-color': 'black',
      },
    });

    const pointCenter = turfGetCenter(alertDetails.geom);

    // we need the coordintes in the form 'y,x'
    const center = flip(pointCenter);

    // 'goto location' is handled in src/components/Map/Map.tsx
    dispatch(
      AppActions.setInitialLocation({
        type: LocationSelectionType.Position,
        position: center.geometry.coordinates,
        zoom: 10,
        settings: {
          hasFlyToAnimation: true,
        },
      }),
    );
    setMapLoading(false);
  };

  useEffect(() => {
    if (!mapContainer || !alertDetails?.geom) return;

    setMapLoading(true);
    if (mapContainer.loaded()) {
      addLayerToMap();
    } else {
      mapContainer.once('load', () => {
        addLayerToMap();
      });
    }
    return () => {
      /**
       * even though we have used 'optional chaining' here, in some cases maplibre throws -
       * - error: 'cannot read properties of undefined (reading removelayer)'.
       * easiest way to handle this scenario is to wrap it in a try/catch block
       */
      try {
        if (mapContainer) {
          mapContainer?.getMap?.()?.removeLayer?.('alert_polygon_geom');
          mapContainer?.getMap?.()?.removeSource?.('alert-geojson');
        }
      } catch (err) {
        console.log(err);
      }
    };
  }, [alertDetails, mapContainer]);

  return (
    <Box
      sx={{
        width: '100%',
        height: '100%',
        padding: theme.spacing(2),
        overflowY: 'auto',
      }}
    >
      <Button
        variant="outlined"
        onClick={() => navigate('/alerts')}
        sx={{
          padding: theme.spacing(1),
          backgroundColor: theme.palette.grey[50],
          borderRadius: '30px',
          color: theme.palette.secondary.main,
          border: 'none',
          '&:hover': {
            border: 'none',
          },
        }}
      >
        <Icon
          name="ArrowSmallLeft"
          size={18}
          color={theme.palette.secondary.main}
        />
        <Typography variant="body2" sx={{ fontWeight: 700 }}>
          {I18n.t('alertsList.backToAlertsList')}
        </Typography>
      </Button>
      {mapLoading ? (
        <Box
          sx={{
            marginTop: theme.spacing(20),
          }}
        >
          <Loader />
        </Box>
      ) : (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 2,
            marginTop: 2,
          }}
        >
          <AlertDetails
            alertDetails={alertDetails}
            availableLanguages={availableLanguages}
          />
        </Box>
      )}
    </Box>
  );
};

export default AlertDetailView;
