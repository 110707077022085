import { useEffect, useState } from "react";

import { StyledSearch } from "./styles";
import { Color } from "../../constants";
import { I18n } from "../../i18n";
import Icon from "../Icon";

interface SearchProps {
    handleSearch: (input: string) => void;
    placeHolder: string,
    initialSearchTerm: string
}

export const SearchInput = ({ handleSearch, initialSearchTerm, placeHolder }: SearchProps): JSX.Element => {
    //A local state used here to provide debouncing logic
    const [searchTerm, setSearchTerm] = useState(initialSearchTerm);

    useEffect(() => {
        setSearchTerm(initialSearchTerm);
    }, [])

    useEffect(() => {
        const timerId = setTimeout(() => {
            handleSearch(searchTerm);
        }, 500);
        return () => clearTimeout(timerId);
    }, [searchTerm]);

    return (
        <StyledSearch>
            <Icon name="Search" color={Color.DarkBlue} size={20} />
            <input
                placeholder={placeHolder}
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
            />
        </StyledSearch>);
}
