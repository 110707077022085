export enum Color {
    DarkBlue = "#19213e",
    WaterBlue = "#1599d8",
    UniformBlue = "#0e4e9a",
    LeafGreen = "#00857C",
    PrimaryGreen = "#08C296",
    FireRed = "#e32d57",
    DarkRed = "#a72846",
    Orange = "#f2994a",
    White = "#ffffff",
    Grey50 = "#f4f4f5",
    Grey100 = "#e8e9ec",
    Grey200 = "#d1d3d8",
    Grey300 = "#babcc5",
    Grey500 = "#8c909e",
    Grey600 = "#757a8b",
    BodyText = "#121620",
    NeutralGrey = "#5A5B66",
    AntiFlashWhite = "#F0F0F0",
    WhiteSmoke = "#FFF1F0",
    PaleRed = "#FFA39E",
}


export const zoneStatusColors = {
    "normal": {
        iconColor: "#fff",
    },
    "shelter in place": {
        iconColor: "#c46bd9",
    },
    "training": {
        iconColor: "#426769",
    },
    "advisory": {
        iconColor: "#6395c8",
    },
    "evacuation warning": {
        iconColor: "#FADB14",
    },
    "evacuation order": {
        iconColor: "#da7074",
    },
    "evacuation order lifted": {
        iconColor: "#89d268",
    }
}
