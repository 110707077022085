import { ReactComponent as About } from './about.svg';
import { ReactComponent as Accessibility } from './accessibility.svg';
import { ReactComponent as AddAlert } from './add_alert.svg';
import { ReactComponent as AddLocation } from './add_location.svg';
import { ReactComponent as Air } from './air.svg';
import { ReactComponent as Alert } from './alert.svg';
import { ReactComponent as AlertSelected } from './alert_selected.svg';
import { ReactComponent as Alertus } from './alertus.svg';
import { ReactComponent as All } from './all.svg';
import { ReactComponent as AnimalShelter } from './animal_shelter.svg';
import { ReactComponent as Antenna } from './antenna.svg';
import { ReactComponent as AppleColor } from './apple_color.svg';
import { ReactComponent as Area } from './area.svg';
import { ReactComponent as ArrowBigDown } from './arrow_big_down.svg';
import { ReactComponent as ArrowBigLeft } from './arrow_big_left.svg';
import { ReactComponent as ArrowBigRight } from './arrow_big_right.svg';
import { ReactComponent as ArrowBigUp } from './arrow_big_up.svg';
import { ReactComponent as ArrowSmallDown } from './arrow_small_down.svg';
import { ReactComponent as ArrowSmallLeft } from './arrow_small_left.svg';
import { ReactComponent as ArrowSmallRight } from './arrow_small_right.svg';
import { ReactComponent as ArrowSmallUp } from './arrow_small_up.svg';
import { ReactComponent as AssitedLiving } from './assited_living.svg';
import { ReactComponent as AvatarSelected } from './avatar_selected.svg';
import { ReactComponent as Back } from './back.svg';
import { ReactComponent as Bookmark } from './bookmark.svg';
import { ReactComponent as BookmarkSelected } from './bookmark_selected.svg';
import { ReactComponent as Bridge } from './bridge.svg';
import { ReactComponent as Briefcase } from './briefcase.svg';
import { ReactComponent as Broadcast } from './broadcast.svg';
import { ReactComponent as Calendar } from './calendar.svg';
import { ReactComponent as Cefs } from './cefs.svg';
import { ReactComponent as Centralize } from './centralize.svg';
import { ReactComponent as Chat } from './chat.svg';
import { ReactComponent as Check } from './check.svg';
import { ReactComponent as Checkbox } from './checkbox.svg';
import { ReactComponent as CheckboxSelected } from './checkbox_selected.svg';
import { ReactComponent as Childcare } from './childcare.svg';
import { ReactComponent as Circle } from './circle.svg';
import { ReactComponent as City } from './city.svg';
import { ReactComponent as ClearFilter } from './clear_filter.svg';
import { ReactComponent as Click } from './click.svg';
import { ReactComponent as Clock } from './clock.svg';
import { ReactComponent as Close } from './close.svg';
import { ReactComponent as Co } from './co.svg';
import { ReactComponent as Cone } from './cone.svg';
import { ReactComponent as Copy } from './copy.svg';
import { ReactComponent as County } from './county.svg';
import { ReactComponent as Crossroad } from './crossroad.svg';
import { ReactComponent as DataSources } from './data_sources.svg';
import { ReactComponent as DegreeSpeaker360 } from './degree_speaker_360.svg';
import { ReactComponent as Desktop } from './desktop.svg';
import { ReactComponent as Direction } from './direction.svg';
import { ReactComponent as Documents } from './documents.svg';
import { ReactComponent as DocumentsSelected } from './documents_selected.svg';
import { ReactComponent as Download } from './download.svg';
import { ReactComponent as Drag } from './drag.svg';
import { ReactComponent as DrawLine } from './draw_line.svg';
import { ReactComponent as Duplicate } from './duplicate.svg';
import { ReactComponent as Earthquake } from './earthquake.svg';
import { ReactComponent as Edit } from './edit.svg';
import { ReactComponent as ElectricalUtilities } from './electrical_utilities.svg';
import { ReactComponent as Email } from './email.svg';
import { ReactComponent as EmailGroup } from './email_group.svg';
import { ReactComponent as Excel } from './excel.svg';
import { ReactComponent as Export } from './export.svg';
import { ReactComponent as Facebook } from './facebook.svg';
import { ReactComponent as FacebookColor } from './facebook_color.svg';
import { ReactComponent as Factory } from './factory.svg';
import { ReactComponent as File } from './file.svg';
import { ReactComponent as Filter } from './filter.svg';
import { ReactComponent as Fire } from './fire.svg';
import { ReactComponent as Flag } from './flag.svg';
import { ReactComponent as Flood } from './flood.svg';
import { ReactComponent as Forest } from './forest.svg';
import { ReactComponent as Fullscreen } from './fullscreen.svg';
import { ReactComponent as Gas } from './gas.svg';
import { ReactComponent as GoogleColor } from './google_color.svg';
import { ReactComponent as GroupAdd } from './group_add.svg';
import { ReactComponent as GroupRemove } from './group_remove.svg';
import { ReactComponent as Hand } from './hand.svg';
import { ReactComponent as Hazard } from './hazard.svg';
import { ReactComponent as Helicopter } from './helicopter.svg';
import { ReactComponent as Help } from './help.svg';
import { ReactComponent as Hide } from './hide.svg';
import { ReactComponent as Home } from './home.svg';
import { ReactComponent as Hospital } from './hospital.svg';
import { ReactComponent as HourGlass } from './hourGlass.svg';
import { ReactComponent as House } from './house.svg';
import { ReactComponent as Hurricane } from './hurricane.svg';
import { ReactComponent as ImageAdd } from './image_add.svg';
import { ReactComponent as Import } from './import.svg';
import { ReactComponent as Incident } from './incident.svg';
import { ReactComponent as IncidentSelected } from './incident_selected.svg';
import { ReactComponent as Information } from './information.svg';
import { ReactComponent as InformationFilled } from './information_filled.svg';
import { ReactComponent as Key } from './key.svg';
import { ReactComponent as Layers } from './layers.svg';
import { ReactComponent as Library } from './library.svg';
import { ReactComponent as Link } from './link.svg';
import { ReactComponent as ListBullet } from './list_bullet.svg';
import { ReactComponent as ListNumber } from './list_number.svg';
import { ReactComponent as Live } from './live.svg';
import { ReactComponent as Loading } from './loading.svg';
import { ReactComponent as Location } from './location.svg';
import { ReactComponent as LocationShow } from './location_show.svg';
import { ReactComponent as Logout } from './logout.svg';
import { ReactComponent as Magnet } from './magnet.svg';
import { ReactComponent as MapSearch } from './map-search.svg';
import { ReactComponent as Map } from './map.svg';
import { ReactComponent as MapSelected } from './map_selected.svg';
import { ReactComponent as Maximize } from './maximize.svg';
import { ReactComponent as Menu } from './menu.svg';
import { ReactComponent as Merge } from './merge.svg';
import { ReactComponent as Microphone } from './microphone.svg';
import { ReactComponent as Minus } from './minus.svg';
import { ReactComponent as Mobile } from './mobile.svg';
import { ReactComponent as MobileSms } from './mobile_sms.svg';
import { ReactComponent as Moon } from './moon.svg';
import { ReactComponent as MoreHorizontal } from './more-horizontal.svg';
import { ReactComponent as MoreVertical } from './more-vertical.svg';
import { ReactComponent as Multiselect } from './multiselect.svg';
import { ReactComponent as NewNotification1 } from './new-notification_1.svg';
import { ReactComponent as New } from './new.svg';
import { ReactComponent as Officer } from './officer.svg';
import { ReactComponent as Open } from './open.svg';
import { ReactComponent as Parameter } from './parameter.svg';
import { ReactComponent as Pause } from './pause.svg';
import { ReactComponent as Pdf } from './pdf.svg';
import { ReactComponent as PhoneCall } from './phone_call.svg';
import { ReactComponent as Pin } from './pin.svg';
import { ReactComponent as Plane } from './plane.svg';
import { ReactComponent as Play } from './play.svg';
import { ReactComponent as Plugin } from './plugin.svg';
import { ReactComponent as Plus } from './plus.svg';
import { ReactComponent as Police } from './police.svg';
import { ReactComponent as Polygon } from './polygon.svg';
import { ReactComponent as Population } from './population.svg';
import { ReactComponent as Preview } from './preview.svg';
import { ReactComponent as Print } from './print.svg';
import { ReactComponent as ProcessAdd } from './process-add.svg';
import { ReactComponent as ProcessCue } from './process-cue.svg';
import { ReactComponent as Profile } from './profile.svg';
import { ReactComponent as ProfileSelected } from './profile_selected.svg';
import { ReactComponent as Radial } from './radial.svg';
import { ReactComponent as RadioButton } from './radio_button.svg';
import { ReactComponent as Rain } from './rain.svg';
import { ReactComponent as Refresh } from './refresh.svg';
import { ReactComponent as Remove } from './remove.svg';
import { ReactComponent as Resend } from './resend.svg';
import { ReactComponent as RoadClosure } from './road_closure.svg';
import { ReactComponent as Route } from './route.svg';
import { ReactComponent as Rss } from './rss.svg';
import { ReactComponent as Ruler } from './ruler.svg';
import { ReactComponent as Save } from './save.svg';
import { ReactComponent as School } from './school.svg';
import { ReactComponent as Search } from './search.svg';
import { ReactComponent as Sensor } from './sensor.svg';
import { ReactComponent as Settings } from './settings.svg';
import { ReactComponent as ShapePolygon } from './shape-polygon.svg';
import { ReactComponent as ShareAndroid } from './share-android.svg';
import { ReactComponent as ShareApple } from './share-apple.svg';
import { ReactComponent as Sheet } from './sheet.svg';
import { ReactComponent as Shield } from './shield.svg';
import { ReactComponent as Shield1 } from './shield_1.svg';
import { ReactComponent as Shield2 } from './shield_2.svg';
import { ReactComponent as Shooter } from './shooter.svg';
import { ReactComponent as ShowList } from './show_list.svg';
import { ReactComponent as ShowTable } from './show_table.svg';
import { ReactComponent as Simulation } from './simulation.svg';
import { ReactComponent as SimulationAdd } from './simulation_add.svg';
import { ReactComponent as Siren } from './siren.svg';
import { ReactComponent as Snow } from './snow.svg';
import { ReactComponent as Split } from './split.svg';
import { ReactComponent as Sprinkler } from './sprinkler.svg';
import { ReactComponent as StagingArea } from './staging_area.svg';
import { ReactComponent as Star } from './star.svg';
import { ReactComponent as StarSelected } from './star_selected.svg';
import { ReactComponent as StatusAdvisory } from './status_-_advisory.svg';
import { ReactComponent as StatusClearToRepopulate } from './status_-_clear_to_repopulate.svg';
import { ReactComponent as StatusEvacuationOrder } from './status_-_evacuation_order.svg';
import { ReactComponent as StatusEvacuationWarning } from './status_-_evacuation_warning.svg';
import { ReactComponent as StatusNormal } from './status_-_normal.svg';
import { ReactComponent as StatusShelterInPlace } from './status_-_shelter_in_place.svg';
import { ReactComponent as StatusDot } from './status_dot.svg';
import { ReactComponent as Stop } from './stop.svg';
import { ReactComponent as Strobe } from './strobe.svg';
import { ReactComponent as Structure } from './structure.svg';
import { ReactComponent as Sun } from './sun.svg';
import { ReactComponent as Support } from './support.svg';
import { ReactComponent as Tag } from './tag.svg';
import { ReactComponent as TagAdd } from './tag_add.svg';
import { ReactComponent as TagRemove } from './tag_remove.svg';
import { ReactComponent as Target } from './target.svg';
import { ReactComponent as TempRefugeArea } from './temp_refuge_area.svg';
import { ReactComponent as Temperature } from './temperature.svg';
import { ReactComponent as Thunder } from './thunder.svg';
import { ReactComponent as TrafficLight } from './traffic_light.svg';
import { ReactComponent as Train } from './train.svg';
import { ReactComponent as Training } from './training.svg';
import { ReactComponent as Translation } from './translation.svg';
import { ReactComponent as TrashBin } from './trash_bin.svg';
import { ReactComponent as Truck } from './truck.svg';
import { ReactComponent as Twitter } from './twitter.svg';
import { ReactComponent as Undo } from './undo.svg';
import { ReactComponent as Unfollow } from './unfollow.svg';
import { ReactComponent as Upload } from './upload.svg';
import { ReactComponent as Vehicle } from './vehicle.svg';
import { ReactComponent as Warning } from './warning.svg';
import { ReactComponent as WaterStructures } from './water_structures.svg';
import { ReactComponent as Waze } from './waze.svg';
import { ReactComponent as Weather } from './weather.svg';
import { ReactComponent as Wheelchair } from './wheelchair.svg';
import { ReactComponent as Wind } from './wind.svg';
import { ReactComponent as WindDirection } from './wind_direction.svg';
import { ReactComponent as World } from './world.svg';
import { ReactComponent as Zone } from './zone.svg';

export default {
  About,
  Accessibility,
  AddAlert,
  AddLocation,
  Air,
  Alert,
  AlertSelected,
  Alertus,
  All,
  AnimalShelter,
  Antenna,
  AppleColor,
  Area,
  ArrowBigDown,
  ArrowBigLeft,
  ArrowBigRight,
  ArrowBigUp,
  ArrowSmallDown,
  ArrowSmallLeft,
  ArrowSmallRight,
  ArrowSmallUp,
  AssitedLiving,
  AvatarSelected,
  Back,
  Bookmark,
  BookmarkSelected,
  Bridge,
  Briefcase,
  Broadcast,
  Calendar,
  Cefs,
  Centralize,
  Chat,
  Check,
  Checkbox,
  CheckboxSelected,
  Childcare,
  Circle,
  City,
  ClearFilter,
  Click,
  Clock,
  Close,
  Co,
  Cone,
  Copy,
  County,
  Crossroad,
  DataSources,
  DegreeSpeaker360,
  Desktop,
  Direction,
  Documents,
  DocumentsSelected,
  Download,
  Drag,
  DrawLine,
  Duplicate,
  Earthquake,
  Edit,
  ElectricalUtilities,
  Email,
  EmailGroup,
  Excel,
  Export,
  Facebook,
  FacebookColor,
  Factory,
  File,
  Filter,
  Fire,
  Flag,
  Flood,
  Forest,
  Fullscreen,
  Gas,
  GoogleColor,
  GroupAdd,
  GroupRemove,
  Hand,
  Hazard,
  Helicopter,
  Help,
  Hide,
  Home,
  Hospital,
  HourGlass,
  House,
  Hurricane,
  ImageAdd,
  Import,
  Incident,
  IncidentSelected,
  Information,
  InformationFilled,
  Key,
  Layers,
  Library,
  Link,
  ListBullet,
  ListNumber,
  Live,
  Loading,
  Location,
  LocationShow,
  Logout,
  Magnet,
  Map,
  MapSearch,
  MapSelected,
  Maximize,
  Menu,
  Merge,
  Microphone,
  Minus,
  Mobile,
  MobileSms,
  Moon,
  MoreHorizontal,
  MoreVertical,
  Multiselect,
  NewNotification1,
  New,
  Officer,
  Open,
  Parameter,
  Pause,
  Pdf,
  PhoneCall,
  Pin,
  Plane,
  Play,
  Plugin,
  Plus,
  Police,
  Polygon,
  Population,
  Preview,
  Print,
  ProcessAdd,
  ProcessCue,
  Profile,
  ProfileSelected,
  Radial,
  RadioButton,
  Rain,
  Refresh,
  Remove,
  Resend,
  RoadClosure,
  Route,
  Rss,
  Ruler,
  Save,
  School,
  Search,
  Sensor,
  Settings,
  ShapePolygon,
  ShareAndroid,
  ShareApple,
  Sheet,
  Shield,
  Shield1,
  Shield2,
  Shooter,
  ShowList,
  ShowTable,
  Simulation,
  SimulationAdd,
  Siren,
  Snow,
  Split,
  Sprinkler,
  StagingArea,
  Star,
  StarSelected,
  StatusAdvisory,
  StatusClearToRepopulate,
  StatusEvacuationOrder,
  StatusEvacuationWarning,
  StatusNormal,
  StatusShelterInPlace,
  Stop,
  Strobe,
  Structure,
  Sun,
  Support,
  Tag,
  TagAdd,
  TagRemove,
  Target,
  TempRefugeArea,
  Temperature,
  Thunder,
  TrafficLight,
  Train,
  Training,
  Translation,
  TrashBin,
  Truck,
  Twitter,
  Undo,
  Unfollow,
  Upload,
  Vehicle,
  Warning,
  WaterStructures,
  Waze,
  Weather,
  Wheelchair,
  Wind,
  WindDirection,
  World,
  Zone,
  StatusDot,
};
