import { cloneDeep } from 'lodash';
import { useEffect, useState } from 'react';

import { Hazard } from '../components/Map/types';
import { useAppSelector, useFetchHazardsQuery } from '../redux-rtk';
import { HazardsSortMethod } from '../redux-rtk/slices/appSlice';

interface PaginatedHazardsData {
  isError: boolean;
  hazardsList: Hazard[];
  hasMore: boolean;
  fetchNextPage: () => void;
}

export const useFetchPaginatedHazards = (
  sortMethod: HazardsSortMethod,
  search: string
): PaginatedHazardsData => {
  const [hazardsList, setHazardsList] = useState<Hazard[]>([]);
  const [offset, setOffset] = useState<number>(0);
  const currentLocation = useAppSelector(
    state => state.user.data.currentLocation,
  );

  useEffect(() => {
    setHazardsList([]);
    setOffset(0);
  }, [sortMethod]);

  const {
    data: hazardsData,
    isFetching,
    isError,
    isSuccess,
  } = useFetchHazardsQuery({
    location: currentLocation?.coordinates,
    offset: offset,
    sortBy: sortMethod,
    search
  });

  if (
    !isFetching &&
    isSuccess &&
    hazardsData?.nextOffset > hazardsList.length &&
    (!hazardsData?.lastPage || hazardsList.length === offset) &&
    // We only need update if we got at least one hazard back
    hazardsData?.hazards.length > 0
  ) {
    setHazardsList(hazardsList.concat(cloneDeep(hazardsData.hazards)));
  }
  const fetchNextPage = () => {
    // The simplest way to force a refresh of the data is to update the offset; we use the latest
    // offset we were given from the most recent fetch and update our local variable, letting the fetch take over from there
    setOffset(hazardsData?.nextOffset || 0);
  };

  return {
    isError,
    hazardsList,
    hasMore: !hazardsData?.lastPage,
    fetchNextPage,
  };
};
