import { AlertCardData } from './types';

export const sampleCardData: AlertCardData[] = [
  {
    id: 1,
    status: 'active',
    critical: true,
    title: {
      en: 'Evacuation Order',
      es: 'Orden de evacuación',
      sl: 'Ukaz za evakuacijo',
      de: 'Evakuierungsbefehl',
      fr: `Ordre d'évacuation`,
      ja: '避難命令',
    },
    reason: {
      en: 'An urgent evacuation order has been issued for your safety due to a wildfire threatening the Maplewood Estates area. This wildfire presents an imminent threat to life and property. Immediate evacuation is necessary to ensure your safety and well-being.',
      es: 'Se ha emitido una orden de evacuación urgente por su seguridad debido a un incendio forestal que amenaza el área de Maplewood Estates. Este incendio forestal presenta una amenaza inminente a la vida y la propiedad. La evacuación inmediata es necesaria para garantizar su seguridad y bienestar.',
      de: 'Zu Ihrer Sicherheit wurde ein dringender Evakuierungsbefehl erlassen, da ein Waldbrand das Gebiet Maplewood Estates bedroht. Dieses verheerende Feuer stellt eine unmittelbare Gefahr für Leben und Eigentum dar. Um Ihre Sicherheit und Ihr Wohlbefinden zu gewährleisten, ist eine sofortige Evakuierung erforderlich.',
      fr: `Un ordre d'évacuation urgent a été émis pour votre sécurité en raison d'un incendie de forêt menaçant la région de Maplewood Estates. Cet incendie de forêt présente une menace imminente pour la vie et les biens. Une évacuation immédiate est nécessaire pour assurer votre sécurité et votre bien-être.`,
      ja: 'メープルウッド エステーツ地域を脅かす山火事のため、皆様の安全を確保するために緊急避難命令が発令されました。この山火事は生命と財産に差し迫った脅威を与えています。あなたの安全と健康を確保するには、ただちに避難する必要があります。',
      sl: 'Zaradi požara, ki ogroža območje Maplewood Estates, je bil zaradi vaše varnosti izdan nujni ukaz o evakuaciji. Ta požar v naravi predstavlja neposredno nevarnost za življenje in premoženje. Za zagotovitev vaše varnosti in dobrega počutja je potrebna takojšnja evakuacija.',
    },
    description: {
      en: 'An urgent evacuation order has been issued for your safety due to an approaching wildfire threatening the Maplewood Estates area. This wildfire presents an imminent threat to life and property. Immediate evacuation is necessary to ensure',
      es: 'Se ha emitido una orden de evacuación urgente por su seguridad debido a que se avecina un incendio forestal que amenaza el área de Maplewood Estates. Este incendio forestal presenta una amenaza inminente a la vida y la propiedad. Es necesaria la evacuación inmediata para garantizar',
    },
    county: 'Monterrey',
    lastUpdated: '2024-09-02T14:25:36Z',
    geom: {
      type: 'FeatureCollection',
      features: [
        {
          type: 'Feature',
          properties: {
            lat: 76.60258942734083,
            long: 12.351911513891196,
          },
          geometry: {
            coordinates: [
              [
                [76.60258942734083, 12.351911513891196],
                [76.59713552158962, 12.327178966491957],
                [76.59636016465362, 12.311912641978608],
                [76.60206682793097, 12.307061526944523],
                [76.61023762315824, 12.284245450260016],
                [76.60030834332315, 12.279636551977617],
                [76.60154327104624, 12.264580989595117],
                [76.61643981655897, 12.258011319751361],
                [76.62016019549918, 12.250225590793107],
                [76.64029708966069, 12.253866192480416],
                [76.6502426505964, 12.261147313543944],
                [76.66490972156151, 12.2611596300669],
                [76.67088300100494, 12.256044496214315],
                [76.69100151902194, 12.263113057644972],
                [76.70467970990455, 12.275009228828182],
                [76.70535171758661, 12.29760014129593],
                [76.70065908484611, 12.324533753671318],
                [76.68676640427901, 12.339350937251723],
                [76.66640929767567, 12.349351374914306],
                [76.65894946140736, 12.35081712314151],
                [76.65347568725105, 12.34910371238935],
                [76.64775610093295, 12.352245951587093],
                [76.64104564998473, 12.350288945732103],
                [76.63186092785628, 12.353408936177331],
                [76.62615304589707, 12.351456996764696],
                [76.62292794849742, 12.354118777803407],
                [76.60829211718669, 12.353373255546785],
                [76.60258942734083, 12.351911513891196],
              ],
            ],
            type: 'Polygon',
          },
        },
      ],
    },
    zonesImpacted: ['GTD-010', 'GTD-020'],
  },
  {
    id: 2,
    status: 'expired',
    critical: true,
    title: {
      en: 'Multiple zones in Evacuation order',
      es: 'Varios zonas en orden de evacuación',
    },
    description: {
      en: 'An urgent evacuation order has been issued for your safety due to an approaching wildfire threatening the Maplewood Estates area. This wildfire presents an imminent threat to life and property. Immediate evacuation is necessary to ensure',
      es: 'Se ha emitido una orden de evacuación urgente por su seguridad debido a que se avecina un incendio forestal que amenaza el área de Maplewood Estates. Este incendio forestal presenta una amenaza inminente a la vida y la propiedad. Es necesaria la evacuación inmediata para garantizar',
    },
    county: 'Monterrey',
    lastUpdated: '2024-09-02T14:25:36Z',
    geom: {
      type: 'FeatureCollection',
      features: [
        {
          type: 'Feature',
          properties: {
            lat: -111.68870334565635,
            long: 32.76617211792657,
          },
          geometry: {
            coordinates: [
              [
                [-111.68870334565635, 32.76617211792657],
                [-111.6880723836451, 32.75477317042879],
                [-111.66255159653747, 32.7545071522826],
                [-111.6682243498799, 32.73900711760153],
                [-111.62901463530704, 32.76232009254733],
                [-111.63860095275646, 32.784976953280335],
                [-111.68854608131781, 32.78910048932076],
                [-111.69642728371704, 32.77942670732955],
                [-111.68870334565635, 32.76617211792657],
              ],
            ],
            type: 'Polygon',
          },
        },
      ],
    },
  },
  {
    id: 3,
    status: 'active',
    critical: true,
    title: {
      en: 'Residents Stranded Due to Flooding in Prundale',
    },
    description: {
      en: 'An urgent evacuation order has been issued for your safety due to an approaching wildfire threatening the Maplewood Estates area. This wildfire presents an imminent threat to life and property. Immediate evacuation is necessary to ensure',
      es: 'Se ha emitido una orden de evacuación urgente por su seguridad debido a que se avecina un incendio forestal que amenaza el área de Maplewood Estates. Este incendio forestal presenta una amenaza inminente a la vida y la propiedad. Es necesaria la evacuación inmediata para garantizar',
    },
    county: 'Monterrey',
    lastUpdated: '2024-09-02T14:25:36Z',
    geom: {
      type: 'FeatureCollection',
      features: [
        {
          type: 'Feature',
          properties: {
            lat: -70.75772672324993,
            long: -33.38121135199804,
          },
          geometry: {
            coordinates: [
              [
                [-70.75772672324993, -33.38121135199804],
                [-70.78537734301847, -33.423347544914044],
                [-70.75473111609412, -33.48752662344186],
                [-70.68800404394132, -33.52804087780514],
                [-70.60587156615567, -33.54295081247633],
                [-70.49191240486633, -33.422089065492706],
                [-70.60404398364615, -33.443969697343796],
                [-70.59007832115314, -33.34981561193584],
                [-70.6994327725808, -33.35363114151357],
                [-70.75772672324993, -33.38121135199804],
              ],
            ],
            type: 'Polygon',
          },
        },
      ],
    },
  },
  {
    id: 4,
    status: 'active',
    critical: true,
    title: {
      en: 'Residents Stranded Due to Flooding in Prundale',
      es: 'Residentes varados debido a inundaciones en Prundale',
    },
    description: {
      en: 'An urgent evacuation order has been issued for your safety due to an approaching wildfire threatening the Maplewood Estates area. This wildfire presents an imminent threat to life and property. Immediate evacuation is necessary to ensure',
      es: 'Se ha emitido una orden de evacuación urgente por su seguridad debido a que se avecina un incendio forestal que amenaza el área de Maplewood Estates. Este incendio forestal presenta una amenaza inminente a la vida y la propiedad. Es necesaria la evacuación inmediata para garantizar',
    },
    county: 'Monterrey',
    lastUpdated: '2024-09-02T14:25:36Z',
    geom: {
      type: 'FeatureCollection',
      features: [
        {
          type: 'Feature',
          properties: {
            lat: 30.285806643092002,
            long: 60.00124795397099,
          },
          geometry: {
            coordinates: [
              [
                [30.285806643092002, 60.00124795397099],
                [30.240141130386917, 59.913835047155175],
                [30.382497182122677, 59.83160518203914],
                [30.672423986160283, 59.90845585134991],
                [30.566468444035763, 59.91989470631978],
                [30.523483769168763, 60.00727212043435],
                [30.285806643092002, 60.00124795397099],
              ],
            ],
            type: 'Polygon',
          },
        },
      ],
    },
  },
  {
    id: 5,
    status: 'active',
    critical: false,
    title: {
      en: 'Multiple zones in Evacuation order',
      es: 'Varios zonas en orden de evacuación',
    },
    description: {
      en: 'An urgent evacuation order has been issued for your safety due to an approaching wildfire threatening the Maplewood Estates area. This wildfire presents an imminent threat to life and property. Immediate evacuation is necessary to ensure',
      es: 'Se ha emitido una orden de evacuación urgente por su seguridad debido a que se avecina un incendio forestal que amenaza el área de Maplewood Estates. Este incendio forestal presenta una amenaza inminente a la vida y la propiedad. Es necesaria la evacuación inmediata para garantizar',
    },
    county: 'Monterrey',
    lastUpdated: '2024-09-02T14:25:36Z',
    geom: {
      type: 'FeatureCollection',
      features: [
        {
          type: 'Feature',
          properties: {
            lat: '76.32778575159108',
            long: '10.46988858670565',
          },
          geometry: {
            coordinates: [
              [
                [76.32778575159108, 10.46988858670565],
                [76.33811797036867, 10.24782714832773],
                [76.46788381817231, 10.05624389931819],
                [76.88228320352425, 9.98792190152922],
                [76.96180179141743, 10.232765366394503],
                [76.84395311680873, 10.620241637532416],
                [76.32778575159108, 10.46988858670565],
              ],
            ],
            type: 'Polygon',
          },
        },
      ],
    },
  },
];
