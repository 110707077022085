import { Box, Button, styled, Typography, useTheme } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';

import Icon from '~/components/Icon';
import { Color } from '~/constants';
import { useIsMobileDevice } from '~/hooks';
import { I18n } from '~/i18n';
import { Card } from '~/layouts/Card';
import { ActiveAlertsCardProps } from '~/pages/AlertsList/types';

const CriticalAlertBox = styled(Box)(({}) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '4px 8px',
  borderRadius: '4px',
  gap: '4px',
  background: Color.WhiteSmoke,
  border: `1px solid ${Color.PaleRed}`,
}));

const ActiveAlertsCard: React.FC<ActiveAlertsCardProps> = ({
  id,
  title,
  description,
  footerData,
  sx,
}) => {
  const isMobile = useIsMobileDevice();
  const theme = useTheme();
  const navigate = useNavigate();

  const handleViewDetailClick = () => {
    navigate(`/alert/${id}`);
  };

  return (
    <Card
      hasShadow={false}
      backgroundColor="white"
      sx={{ display: 'flex', flexDirection: 'column', width: '100%', ...sx }}
      onClick={isMobile ? handleViewDetailClick : () => {}}
    >
      <Box sx={{ display: 'flex' }}>
        <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
          {title && (
            <Typography
              variant="h3"
              sx={{
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                textTransform: 'none',
              }}
            >
              {title}
            </Typography>
          )}
          <Typography
            variant="body2"
            sx={{
              margin: '12px 0',
              overflow: 'hidden',
              // line clamping is done for the description to be max 2 lines and hide the
              // remaining text. Reference: https://css-tricks.com/line-clampin,
              display: '-webkit-box',
              WebkitBoxOrient: 'vertical',
              WebkitLineClamp: 2,
              textOverflow: 'ellipsis',
              lineClamp: 2,
              whiteSpace: 'normal',
            }}
          >
            {description}
          </Typography>
        </Box>
        {isMobile && (
          <Icon name="ArrowSmallRight" size={24} color={Color.NeutralGrey} />
        )}
      </Box>
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Box
          sx={{
            maxWidth: '545px',
            display: 'flex',
            alignItems: 'center',
            flexWrap: 'wrap',
            gap: '12px',
          }}
        >
          {footerData?.critical && (
            <CriticalAlertBox>
              <Icon name="Warning" size={12} color={Color.DarkRed} />
              <Typography
                sx={{ fontSize: 12, fontWeight: 600, color: Color.DarkRed }}
              >
                {I18n.t('alertsList.critical')}
              </Typography>
            </CriticalAlertBox>
          )}
          {footerData?.county && (
            <Box sx={{ display: 'flex', gap: 0.5 }}>
              <Icon name="County" size={14} color={Color.NeutralGrey} />
              <Typography variant="body2" sx={{ color: Color.NeutralGrey }}>
                {I18n.t('alertsList.county')}
              </Typography>
              <Typography variant="body2">{footerData.county}</Typography>
            </Box>
          )}
          {footerData?.date && (
            <Box sx={{ display: 'flex', gap: 0.5 }}>
              <Icon name="Calendar" size={14} color={Color.NeutralGrey} />
              <Typography variant="body2" sx={{ color: Color.NeutralGrey }}>
                {I18n.t('alertsList.date')}
              </Typography>
              <Typography variant="body2">{footerData.date}</Typography>
            </Box>
          )}
          {footerData?.time && (
            <Box sx={{ display: 'flex', gap: 0.5 }}>
              <Icon name="HourGlass" size={14} color={Color.NeutralGrey} />
              <Typography variant="body2" sx={{ color: Color.NeutralGrey }}>
                {I18n.t('alertsList.time')}
              </Typography>
              <Typography variant="body2">{footerData?.time}</Typography>
            </Box>
          )}
        </Box>
        {!isMobile && (
          <Box>
            <Button
              variant="outlined"
              onClick={handleViewDetailClick}
              sx={{
                borderRadius: '5px',
                height: theme.spacing(3),
                padding: '2px 10px',
                '&:hover': {
                  borderColor: theme.palette.primary.light,
                },
              }}
            >
              {I18n.t('alertsList.view')}
            </Button>
          </Box>
        )}
      </Box>
    </Card>
  );
};

export default ActiveAlertsCard;
