import { Navigate, Route, Routes, useLocation } from 'react-router-dom';

import { LocationInformation } from '~/pages/AlertsInLocation/';
import AlertsList from '~/pages/AlertsList';
import AlertDetailView from '~/pages/AlertsList/AlertDetailView/AlertDetailView';

import { ZoneDetails } from '../components/ZoneDetails';
import About from '../pages/About';
import { DownloadApp } from '../pages/DownloadApp';
import { FullscreenMap } from '../pages/FullscreenMap';
import { HazardDetails, Hazards } from '../pages/Hazards';
import NotFound from '../pages/NotFound';
import { Search } from '../pages/Search';

export const Router = () => {
  const location = useLocation();
  return (
    <Routes location={location}>
      <Route path="/search" element={<Search />} />
      <Route path="/location" element={<LocationInformation />} />
      <Route path="/zones/:id" element={<ZoneDetails />} />
      <Route path="/hazards/:id" element={<HazardDetails />} />
      <Route path="/hazards" element={<Hazards />} />
      <Route path="/download" element={<DownloadApp />} />
      <Route path="/about" element={<About />} />
      <Route path="/fullscreenMap" element={<FullscreenMap />} />
      <Route path="/alerts" element={<AlertsList />} />
      <Route path="/alert/:id" element={<AlertDetailView />} />
      <Route path="/" element={<Navigate to="/search" replace />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};
