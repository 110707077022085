import { Box, Button, Typography, useTheme } from '@mui/material';
import { forwardRef } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';

import { ParameterIcon } from '~/components/Icon/ParameterIcon';
import { Loader } from '~/components/Loader';
import { HazardCard } from '~/components/Map/MapCards/HazardCard';
import { useFetchPaginatedHazards } from '~/hooks';
import { I18n } from '~/i18n';
import { useAppSelector } from '~/redux-rtk';

type HazardsListProps = {
  onOpenSortPanel: () => void;
};

export const HazardsList = forwardRef((props: HazardsListProps, ref:React.ForwardedRef<HTMLButtonElement>) => {
  const theme = useTheme();
  const sortMethod = useAppSelector(state => state.app.data.selectedHazardSort);
  const hazardSearchText = useAppSelector(state => state.app.data.hazardSearchTerm);

  const { isError, hazardsList, hasMore, fetchNextPage } =
    useFetchPaginatedHazards(sortMethod, hazardSearchText);

  if (isError) {
    return <Typography variant="body1">{I18n.t('errors.error')}</Typography>;
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Button
        ref={ref}
        variant="textGrayed"
        onClick={props.onOpenSortPanel}
        sx={{ width: 'fit-content', ml: -1,
          '&.Mui-focusVisible': {
            backgroundColor: "grey.25",
            outline: "2px solid #101010",
          },
         }}
        startIcon={<ParameterIcon />}
      >
        {I18n.t('fires.sortBy')}
        <Typography
          variant="button"
          sx={{ marginLeft: '.3em', fontWeight: 'bold' }}
        >
          {I18n.t(
            `fires.sortBy${sortMethod
              .charAt(0)
              .toUpperCase()}${sortMethod.substring(1)}`,
          )}
        </Typography>
      </Button>

      <InfiniteScroll
        dataLength={hazardsList?.length || 0}
        next={fetchNextPage}
        hasMore={hasMore}
        loader={<Loader />}
        scrollableTarget="scrollableContent"
        style={{
          gap: theme.spacing(2),
          marginTop: theme.spacing(2),
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'column',
          overflow: 'visible',
        }}
        endMessage={
          <Typography
            variant="caption"
            sx={{ textAlign: 'center', display: 'block', mt: 2 }}
          >
            {I18n.t('fires.allLoaded')}
          </Typography>
        }
      >
        {hazardsList.map(hazard => (
          <HazardCard
            key={hazard.id}
            hazard={hazard}
            sx={{ width: '100%' }}
            isCloseButtonVisible={false}
            // userCoordinates={userCoordinates}
          />
        ))}
      </InfiniteScroll>
    </Box>
  );
});
