import DoneIcon from '@mui/icons-material/Done';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Select, MenuItem, FormControl, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import React, { FC, useState } from 'react';

import { Color } from '~/constants';

import { SelectComponentProps } from './types';

// Default MenuProps with custom styles
const CustomMenuProps = {
  PaperProps: {
    style: {
      border: `2px solid ${Color.AntiFlashWhite}`,
      borderRadius: 6,
    },
  },
};

const CustomSelect = styled(Select)<{
  showDropdownArrow: boolean;
  showInputBorder: boolean;
}>(({ theme, showDropdownArrow, showInputBorder }) => ({
  // Styles for the select element
  minHeight: theme.spacing(2),
  '& .MuiSelect-select': {
    padding: theme.spacing(0.5, 1),
    borderRadius: theme.spacing(0.5),
    backgroundColor: theme.palette.background.paper,
    boxShadow: 'none',
    fontSize: '14px',
    fontWeight: 600,
  },

  // Conditionally apply border based on showInputBorder prop
  '& .MuiOutlinedInput-notchedOutline': {
    border: showInputBorder ? `1px solid ${theme.palette.grey[200]}` : 'none',
  },

  // Conditionally display the default dropdown arrow
  '& .MuiSelect-icon': {
    display: showDropdownArrow ? 'inline-flex' : 'none',
  },
}));

const CustomMenuItem = styled(MenuItem)<{
  selectedItem: boolean;
}>(({ theme, selectedItem }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  backgroundColor: `${theme.palette.common.white} !important`,
  fontWeight: 400,
  fontSize: '14px',

  '&:not(:last-child)': {
    borderBottom: `1px solid ${Color.AntiFlashWhite}`,
  },

  '&.Mui-selected': {
    backgroundColor: theme.palette.action.selected,
  },

  // Tick icon visibility
  '& .tick-icon': {
    visibility: selectedItem ? 'visible' : 'hidden',
  },
}));

// Styled DoneIcon as TickIcon
const TickIcon = styled(DoneIcon)(({ theme }) => ({
  color: theme.palette.primary.main,
}));

const SelectComponent: FC<SelectComponentProps> = ({
  options,
  selectedValue,
  onChange,
  showDropdownArrow = true,
  showInputBorder = true,
  icon,
}) => {
  const [open, setOpen] = useState(false);

  return (
    <FormControl fullWidth variant="outlined">
      <CustomSelect
        value={selectedValue}
        onChange={(event: React.ChangeEvent<any>) => {
          onChange(event);
          setOpen(false);
        }}
        onOpen={() => setOpen(true)}
        onClose={() => setOpen(false)}
        displayEmpty
        open={open}
        MenuProps={{
          ...CustomMenuProps,
          PaperProps: {
            ...CustomMenuProps.PaperProps,
            style: {
              ...CustomMenuProps.PaperProps.style,
            },
          },
        }}
        // Render the selected value with optional icon
        renderValue={selected => (
          <span style={{ display: 'flex', alignItems: 'center' }}>
            {icon && <span style={{ marginRight: 8 }}>{icon}</span>}
            {options.find(option => option.value === selected)?.label}
          </span>
        )}
        showDropdownArrow={showDropdownArrow}
        showInputBorder={showInputBorder}
        IconComponent={ExpandMoreIcon}
      >
        {options.map(option => (
          <CustomMenuItem
            key={option.value}
            value={option.value}
            selectedItem={selectedValue === option.value}
          >
            <Typography sx={{ paddingRight: 2 }}>{option.label}</Typography>
            <TickIcon className="tick-icon" />
          </CustomMenuItem>
        ))}
      </CustomSelect>
    </FormControl>
  );
};

export default SelectComponent;
