import { Box } from '@mui/material';
import { delay } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useMap } from 'react-map-gl';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { getAddressFromLocation } from '~/api';
import { Loader } from '~/components/Loader';
import { AlertId, ZoneId } from '~/components/Map/config/layers';
import { SelectedLocation } from '~/components/SelectedLocation/SelectedLocation';
import { MiscZoneInfo } from '~/components/ZoneDetails/MiscZoneInfo';
import { Color, zoneStatusColors, NotFound } from '~/constants';
import { Page } from '~/layouts/Page';
import {
  useAppDispatch,
  useAppSelector,
  useFetchZoneDetailsQuery,
} from '~/redux-rtk';
import {
  AppActions,
  LocationSelection,
  LocationSelectionType,
} from '~/redux-rtk/slices/appSlice';

import AlertCard from './AlertCard';

type Props = {
  isCurrentLocation?: boolean;
};

export const LocationInformation = ({ isCurrentLocation }: Props) => {
  const [selectedAddress, setSelectedAddress] = useState('');
  const [searchParams, setSearchParams] = useSearchParams();
  const [mapLoading, setMapLoading] = useState(false);
  const { mapContainer } = useMap();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const featureCollection = useAppSelector(
    state => state.app.data.featureCollection,
  );
  const selectedLocation = useAppSelector(
    state => state.app.data.selectedLocation,
  );

  useEffect(() => {
    getAddressFromLocation(
      selectedLocation?.position as [number, number],
      selectedLocation?.zoom,
    )
      .then(response => {
        if (response?.address) {
          setSelectedAddress(response.address?.LongLabel);
        }
      })
      .catch(err => console.log(err));
  }, [selectedLocation]);

  /** need to make this more efficient 
      > turn off the events after layers have been loaded, to restrict unnecessary re-rendering 
      > reuse the layer loading mechanism from evac
  **/
  const handleMapLoading = () => {
    if (mapContainer) {
      if (mapContainer?.getMap()?.isSourceLoaded('evacuation_zone')) {
        delay(queryFeaturesFromMapLayers, 5000);
      } else {
        mapContainer?.getMap()?.on('data', e => {
          if (e.sourceId == 'evacuation_zone') queryFeaturesFromMapLayers();
        });
      }
    }
  };

  useEffect(() => {
    //if navigated from search page
    const searchTerm = searchParams.get('search');
    if (searchTerm || !featureCollection?.length) {
      //fetch alerts from map api
      handleMapLoading();
    }
  }, [featureCollection, searchParams, mapContainer, selectedLocation]);

  const queryFeaturesFromMapLayers = () => {
    const pixelCoordinates = mapContainer.project(
      [...selectedLocation.position].reverse() as [number, number],
    );
    const featuresInLocation = mapContainer?.queryRenderedFeatures(
      pixelCoordinates,
      { layers: [ZoneId, AlertId] },
    );
    if (!featuresInLocation?.length) return;
    const processedFeatures: LocationSelection[] = featuresInLocation
      .map(feature => {
        if (ZoneId === feature.layer.id) {
          return {
            id: feature.properties?.identifer,
            type: LocationSelectionType?.Zone,
            position: selectedLocation?.position,
            details: {
              status: feature.properties?.status,
            },
          };
        }
        if (AlertId === feature.layer.id) {
          return {
            id: feature.properties?.identifer,
            type: LocationSelectionType?.Alert,
            position: selectedLocation?.position,
          };
        }
      })
      .filter(feature => !!feature);
    dispatch(AppActions.updateFeatureCollection([...processedFeatures]));
  };

  const getAlertsFromEvac = () => {
    return featureCollection?.filter(
      (collection: any) =>
        collection.type === LocationSelectionType.Zone &&
        collection?.details?.status?.toLowerCase() !== 'normal',
    );
  };

  const getAlertsFromALERTS = () => {
    return featureCollection
      ?.filter(
        (collection: any) => collection.type === LocationSelectionType.Alert,
      )
      .map(collection => {
        return { id: collection.id, type: collection.type };
      });
  };

  const { data, isLoading, isError } = useFetchZoneDetailsQuery(
    getAlertsFromEvac()?.[0]?.id,
    {
      skip: !getAlertsFromEvac()?.[0]?.id,
    },
  );

  const getEvacAlertStatusColor = (status: string) => {
    return zoneStatusColors[status.toLowerCase()]?.iconColor;
  };

  return (
    <Page hasBackButton onBackPressNavigateTo={'/search'}>
      {mapLoading || (isLoading && <Loader />)}
      <SelectedLocation
        zoneId={getAlertsFromEvac()?.[0]?.id}
        location={selectedAddress}
      />
      <Box
        sx={{
          display: 'flex',
          gap: '10px',
          flexDirection: 'column',
        }}
      >
        {data && !!getAlertsFromEvac()?.length && (
          <AlertCard
            zoneId={getAlertsFromEvac()?.[0]?.id}
            statusReason={data?.zone?.statusReason}
            status={data?.zone?.status}
            clickOnView={() => {
              navigate(`/zones/${getAlertsFromEvac()?.[0]?.id}`);
            }}
            statusColor={getEvacAlertStatusColor(data?.zone?.status)}
          />
        )}
        {!!getAlertsFromALERTS()?.length && (
          <AlertCard status="Warning From ALERT" statusColor="red" />
        )}
        {data?.zone && <MiscZoneInfo zone={data?.zone} />}
      </Box>
    </Page>
  );
};
