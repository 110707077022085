import { geocode, IGeocodeResponse, suggest, reverseGeocode, IReverseGeocodeResponse, IEndpointOptions, ILocation } from '@esri/arcgis-rest-geocoding';
import { ApiKeyManager } from '@esri/arcgis-rest-request';
import { Position } from 'geojson';

const getLocations = async (searchTerm: string, position: Position) => {
  const suggestData = await getSearchResults(searchTerm, position);
  return suggestData?.suggestions;
};

const getSearchResults = async (text: string, position: Position) => {
  try {
    const authentication = ApiKeyManager.fromKey(
      process.env.REACT_APP_ESRI_APIKEY,
    );
    const response = await suggest(text, {
      authentication,
      params: {
        // category: 'Address,POI,Postal,Populated Place',
        maxSuggestions: 6,
        returnCollections: false,
        location: `${position[1]},${position[0]}`,
      },
    });
    return response;
  } catch (err) {
    return null;
  }
};


const getCoordsForSearchSuggestion = async (
  suggestion: {
    value: string;
    label: string;
  },
  mapCenter: Position,
): Promise<IGeocodeResponse> => {
  try {
    const authentication = ApiKeyManager.fromKey(
      process.env.REACT_APP_ESRI_APIKEY,
    );
    const response = await geocode({
      authentication,
      singleLine: suggestion.label,
      magicKey: suggestion.value,
      params: {
        location: `${mapCenter[1]},${mapCenter[0]}`,
      },
    });

    return response;
  } catch (err) {
    return null;
  }
};

const getAddressFromLocation = async (
  location: [number, number],
  z: number
): Promise<IReverseGeocodeResponse> => {
  try {
    const authentication = ApiKeyManager.fromKey(
      process.env.REACT_APP_ESRI_APIKEY,
    );
    const response = await reverseGeocode({
      x: location[1],
      y: location[0],
      z
    }, {
      authentication,
    });
    return response;
  } catch (err) {
    return null;
  }
};

export { getLocations, getCoordsForSearchSuggestion, getAddressFromLocation };
