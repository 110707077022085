import { ExpressionSpecification, MapGeoJSONFeature } from 'maplibre-gl';
import { LayerProps } from 'react-map-gl/maplibre';

import { MarkersNames } from '~/constants/markers';

import { layersConfig } from './layers.config';

export const FireId = 'fire_points';
export const ZoneId = 'evacuation_zone';
export const TrafficControlPointId = 'zone_traffic_control_point';
export const ArrivalPointId = 'arrival_point';
export const PointFeatureSourceId = 'ap_tcp_group';
export const AlertId = 'alerts_alert'

// Arrival Point Type Mapping.
// NOTE:
//   The ORDER of these is critically important; we're using an array so that we could more easily
//   use it within ExpressionSpecifications when rendering these layers with MapLibre, but we must
//   maintain this order to keep all of the lookups working correctly
export const arrivalPointTypeMapping = [
  MarkersNames.OnRamp, // 1
  MarkersNames.EvacuationCenter, // 2
  MarkersNames.AnimalShelter, // 3
  MarkersNames.ResourceCenter, // 4
  MarkersNames.Shelter, // 5
  MarkersNames.RallyPoint, // 6
];

export const tcpTypes = [
  MarkersNames.RoadClosure,
  MarkersNames.TrafficControlPoint,
];

export const getPointOfInterestType = (
  feature: MapGeoJSONFeature,
  sourceLayer: string = feature?.layer?.id,
) => {
  if (TrafficControlPointId === sourceLayer) {
    // TCPs have the correct type built-in
    return feature.properties.tcp_type;
  } else {
    // Arrival Point; need to adjust by one to match the index properly
    return arrivalPointTypeMapping[feature.properties.arrival_point_type - 1];
  }
};

interface MapLayers {
  countyNameLayer: LayerProps;
  firePointsLayer: LayerProps;
  firePerimetersLayer: LayerProps[];
  evacuationZoneLayer: LayerProps[];
  countyBoundaryLayer: LayerProps[];
  evacuationZoneIdLayer: LayerProps;
  pointFeatureLayers: LayerProps[];
}

export const TEXT_FONT = ['Arial Unicode MS Bold'];

// Common to all Arrival Point and TCP layers
const basePointLayers = {
  minzoom: 10,
  source: PointFeatureSourceId,
  layout: {
    'icon-size': [
      'interpolate',
      ['linear'],
      ['zoom'],
      10,
      0,
      12,
      0.3,
      14,
      0.5,
      15,
      0.65,
    ] as ExpressionSpecification,
    'text-allow-overlap': true,
    'icon-allow-overlap': true,
  },
};

// Common to the "normal" and "selection" arrival point layers0
const baseArrivalPointLayers = {
  ...basePointLayers,
  'source-layer': ArrivalPointId,
  layout: {
    ...basePointLayers.layout,
    'icon-image': [
      'image',
      [
        'coalesce',
        [
          'at',
          // Need to match the _index_, so subtract 1
          ['-', ['get', 'arrival_point_type'], 1],
          ['literal', arrivalPointTypeMapping],
        ],
        MarkersNames.AnimalShelter,
      ],
    ] as ExpressionSpecification,
  },
};

// Common to the "normal" and "selection" traffic control point layers
const baseTrafficControlPointLayers = {
  ...basePointLayers,
  'source-layer': TrafficControlPointId,
  layout: {
    ...basePointLayers.layout,
    'icon-image': [
      'coalesce',
      ['get', 'tcp_type'],
      // Use TCP icon as the fallback
      MarkersNames.TrafficControlPoint,
    ] as ExpressionSpecification,
  },
};

const selectedIconSize = [
  'interpolate',
  ['linear'],
  ['zoom'],
  10,
  0,
  12,
  0.4,
  14,
  0.65,
  15,
  0.8,
] as ExpressionSpecification;

const mapLayers: MapLayers = {
  evacuationZoneLayer: [
    {
      id: ZoneId,
      type: 'fill',
      source: ZoneId,
      'source-layer': ZoneId,
      layout: {
        visibility: 'visible',
      },
      paint: {
        'fill-color': ['get', 'color'],
        'fill-opacity': 0.4,
        'fill-outline-color': '#232323',
      },
      minzoom: layersConfig.minZoomLevel.evacuationZone,
      maxzoom: layersConfig.maxZoomLevel.evacuationZone,
    },
    {
      id: 'evacuation_zones_community_view_border',
      type: 'line',
      source: ZoneId,
      'source-layer': ZoneId,
      layout: {
        visibility: 'visible',
      },
      paint: {
        'line-color': '#1D4159',
        'line-opacity': 0.6,
      },
      minzoom: layersConfig.minZoomLevel.evacuationZone,
      maxzoom: layersConfig.maxZoomLevel.evacuationZone,
    },
  ],
  evacuationZoneIdLayer: {
    id: 'evacuation_zone_id',
    type: 'symbol',
    source: 'evacuation_zone_id',
    'source-layer': 'evacuation_zone_id',
    layout: {
      visibility: 'visible',
      'text-font': TEXT_FONT,
      'text-field': ['get', 'zone_id'],
      'text-size': [
        'interpolate',
        ['linear'],
        ['zoom'],
        1,
        0,
        10,
        0,
        11,
        16,
        18,
        30,
        19,
        36,
        20,
        39,
        21,
        41,
        22,
        43,
      ],
      'symbol-placement': 'point',
    },
    paint: {
      'text-color': [
        'case',
        ['boolean', ['feature-state', 'hover'], false],
        'rgba(255,0,0,0.75)',
        'rgba(0,0,0,0.75)',
      ],
      'text-halo-color': [
        'case',
        ['boolean', ['feature-state', 'hover'], false],
        'rgba(255,255,0,0.75)',
        'rgba(255,255,255,0.75)',
      ],
      'text-halo-width': 1,
      'text-halo-blur': 0,
    },
    minzoom: layersConfig.minZoomLevel.evacuationZoneId,
    maxzoom: layersConfig.maxZoomLevel.evacuationZoneId,
  },
  countyBoundaryLayer: [
    {
      id: 'county_boundaries_underlay',
      type: 'line',
      source: 'county_boundary',
      'source-layer': 'county_boundary',
      layout: {
        visibility: 'visible',
      },
      paint: {
        'line-color': '#FFFF00',
        'line-width': 4,
        'line-opacity': 0.5,
      },
      minzoom: layersConfig.minZoomLevel.countyBoundary,
      maxzoom: layersConfig.maxZoomLevel.countyBoundary,
    },
    {
      id: 'county_boundary',
      type: 'line',
      source: 'county_boundary',
      'source-layer': 'county_boundary',
      layout: {
        visibility: 'visible',
        'line-join': 'round',
      },
      paint: {
        'line-color': '#4e0000',
        'line-width': 2,
        'line-dasharray': [1, 0.8],
      },
      minzoom: layersConfig.minZoomLevel.countyBoundary,
      maxzoom: layersConfig.maxZoomLevel.countyBoundary,
    },
    {
      id: 'county_fill',
      type: 'fill',
      source: 'county_boundary',
      'source-layer': 'county_boundary',
      layout: {
        visibility: 'visible',
      },
      paint: {
        'fill-color': '#FFFFFF',
        'fill-opacity': 0,
      },
      minzoom: layersConfig.minZoomLevel.countyBoundary,
      maxzoom: layersConfig.maxZoomLevel.countyBoundary,
    },
  ],
  countyNameLayer: {
    id: 'county_name',
    type: 'symbol',
    source: 'county_name',
    'source-layer': 'county_name',
    layout: {
      visibility: 'visible',
      'text-font': TEXT_FONT,
      'text-field': ['get', 'county_name'],
      'text-size': 16,
      'symbol-placement': 'point',
      'text-allow-overlap': true,
    },
    paint: {
      'text-color': [
        'case',
        ['boolean', ['feature-state', 'hover'], false],
        'rgba(255,0,0,0.75)',
        'rgba(0,0,0,0.75)',
      ],
      'text-halo-color': [
        'case',
        ['boolean', ['feature-state', 'hover'], false],
        'rgba(255,255,0,0.75)',
        'rgba(255,255,255,0.75)',
      ],
      'text-halo-width': 1,
      'text-halo-blur': 0,
    },
    minzoom: layersConfig.minZoomLevel.countyName,
  },
  firePerimetersLayer: [
    {
      id: 'fire_perimeters_fill',
      type: 'fill',
      source: 'fire_perimeters',
      'source-layer': 'fire_perimeters',
      layout: {
        visibility: 'visible',
      },
      paint: {
        'fill-color': '#FF0000',
        'fill-opacity': 0.3,
      },
      minzoom: layersConfig.minZoomLevel.fire,
      maxzoom: layersConfig.maxZoomLevel.fire,
    },
    {
      id: 'fire_perimeters_line',
      type: 'line',
      source: 'fire_perimeters',
      'source-layer': 'fire_perimeters',
      layout: {
        visibility: 'visible',
      },
      paint: {
        'line-color': '#FF0000',
        'line-opacity': 0.8,
        'line-width': 2.5,
      },
      minzoom: layersConfig.minZoomLevel.fire,
      maxzoom: layersConfig.maxZoomLevel.fire,
    },
  ],
  firePointsLayer: {
    id: FireId,
    type: 'symbol',
    source: FireId,
    minzoom: 1,
    'source-layer': FireId,
    layout: {
      'icon-image': 'fire', // reference the image
      'icon-size': [
        'interpolate',
        ['linear'],
        ['zoom'],
        1,
        0.15,
        8,
        0.65,
        22,
        1,
      ], // zoom level 4 - size 0, zoom level 14 - size 0.42
      'text-allow-overlap': true,
      'icon-allow-overlap': true,
    },
  },
  pointFeatureLayers: [
    {
      ...baseTrafficControlPointLayers,
      id: TrafficControlPointId,
      type: 'symbol',
      paint: {
        'icon-opacity': [
          'case',
          ['==', ['feature-state', 'selected'], true],
          0,
          1,
        ] as ExpressionSpecification,
      },
    },
    {
      // I tried hard to _not_ have an additional "selected" layer.
      // Unfortunately:
      // - `icon-size` does not support feature state expressions
      // - Using `zoom` as the input to `interpolate` like we do for the icon-size when it's _not_
      //   selected does not work if it's not a "top-level" rule like we would do if we wanted to
      //   use `match` on the feature id to conditionally enlarge the selected features
      ...baseTrafficControlPointLayers,
      id: `${TrafficControlPointId}_selected`,
      type: 'symbol',
      layout: {
        ...baseTrafficControlPointLayers.layout,
        'icon-size': selectedIconSize,
      },
      paint: {
        'icon-opacity': [
          'case',
          ['==', ['feature-state', 'selected'], true],
          1,
          0,
        ] as ExpressionSpecification,
      },
    },
    {
      ...baseArrivalPointLayers,
      id: ArrivalPointId,
      type: 'symbol',
      layout: {
        ...baseArrivalPointLayers.layout,
      },
      paint: {
        'icon-opacity': [
          'case',
          ['==', ['feature-state', 'selected'], true],
          0,
          1,
        ] as ExpressionSpecification,
      },
    },
    {
      ...baseArrivalPointLayers,
      type: 'symbol',
      id: `${ArrivalPointId}_selected`,
      layout: {
        ...baseArrivalPointLayers.layout,
        'icon-size': selectedIconSize,
      },
      paint: {
        'icon-opacity': [
          'case',
          ['==', ['feature-state', 'selected'], true],
          1,
          0,
        ] as ExpressionSpecification,
      },
    },
  ],
};

export const getMapLayers = (): (LayerProps[] | LayerProps)[] =>
  Object.values(mapLayers);
