import styled from "styled-components";

import { Color } from "../../constants";

// import { breakpoints } from "../../styles";

const StyledSearch = styled.div`
  width: 360px;
  height: 48px;
  border-radius: 30px;
  border: 1px solid ${Color.Grey300};
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px 15px;
  background-color: ${Color.White};

  input {
    flex: none;
    order: 1;
    align-self: stretch;
    flex-grow: 0;
    font-family: "Roboto";
    font-size: 14px;
    display: flex;
    align-items: center;
    margin: 0px 10px;
    border: none;
    width: 90%;
    color: ${Color.Grey600};
  }
  input:focus {
    outline: none;
  }
`;

export { StyledSearch };