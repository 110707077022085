import Box from '@mui/material/Box';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Typography from '@mui/material/Typography';
import { visuallyHidden } from '@mui/utils';
import * as React from 'react';

import Icon from '~/components/Icon';
import { Color } from '~/constants';
import { theme } from '~/theme';


interface HeadCell {
    disablePadding: boolean;
    id: string;
    label: string;
    numeric: boolean;
    icon?: string
}

const headCells: readonly HeadCell[] = [
    {
        id: 'title',
        numeric: false,
        disablePadding: true,
        label: 'Name',
    },
    {
        id: 'county',
        numeric: true,
        disablePadding: false,
        label: 'County',
        icon: "County"
    },
    {
        id: 'date',
        numeric: true,
        disablePadding: false,
        label: 'Date',
        icon: "Calendar"
    },
    {
        id: 'time',
        numeric: true,
        disablePadding: false,
        label: 'time',
        icon: 'Clock'
    },
    {
        id: 'link',
        numeric: true,
        disablePadding: false,
        label: 'link',
    },
];


type Order = 'asc' | 'desc';

interface EnhancedTableProps {
    onRequestSort: (event: React.MouseEvent<unknown>, property: string) => void;
    order: Order;
    orderBy: string;
}


export default function EnhancedTableHead(props: EnhancedTableProps) {
    const { order, orderBy, onRequestSort } =
        props;

    return (
        <TableHead>
            <TableRow>
                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        align={'left'}
                        sortDirection={orderBy === headCell.id ? order : false}
                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={(e) => onRequestSort(e, headCell.id)}
                        >
                            <Box
                                sx={{
                                    display: 'flex',
                                    gap: theme.spacing(1),
                                }}
                            >
                                {headCell.icon && <Icon name={headCell.icon} size={14} color={Color.NeutralGrey} />}
                                <Typography
                                    variant='body2'
                                    fontWeight={600}
                                >
                                    {headCell.label}
                                </Typography>
                            </Box>
                            {orderBy === headCell.id ? (
                                <Box component="span" sx={visuallyHidden}>
                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </Box>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}