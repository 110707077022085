import { Badge, Box, Typography, useTheme } from '@mui/material';
import { Link, useLocation } from 'react-router-dom';

import { useIsMobileDevice } from '~/hooks';

import { Color } from '../../constants';
import Icon from '../Icon';

interface NavbarItemProps {
  text: string;
  icon: string;
  link: string;
  isActive?: boolean;
  badgeContent?: React.ReactNode;
}

const NavbarItem = ({ text, icon, link, badgeContent }: NavbarItemProps) => {
  const isMobile = useIsMobileDevice();
  const locationPath = useLocation();
  const theme = useTheme();
  const isActive =
    locationPath.pathname.startsWith(link) ||
    (locationPath.pathname.startsWith('/alert/') && link === '/search');
  const color = theme.palette.secondary.main;

  const NavLink = () => (
    <Link to={link} key={locationPath.pathname} style={{ width: '100%' }}>
      <Box
        sx={theme => ({
          py: 2,
          paddingLeft: 3,
          gap: 2,
          color: color,
          width: '100%',
          display: 'flex',
          borderStyle: 'solid',
          justifyContent: 'start',
          borderWidth: '0 4px 0 0',
          borderTopRightRadius: '2px',
          boxShadow: isActive ? 1 : 0,
          borderBottomRightRadius: '2px',
          transition: 'all 0.3s ease-in-out',
          fontWeight: isActive ? 'bold' : 'normal',
          '-webkit-tap-highlight-color': 'transparent',
          backgroundColor: isActive ? '#fff' : 'transparent',
          borderColor: isActive ? Color.PrimaryGreen : 'transparent',
          [theme.breakpoints.down('md')]: {
            height: '100%',
            px: 1,
            gap: 0,
            flex: 1,
            width: '100%',
            zIndex: 10,
            borderWidth: '0 4px 0 0',
            borderTopLeftRadius: '2px',
            borderTopRightRadius: '2px',
            borderBottomRightRadius: '0px',
            backgroundColor: isActive ? '#fff' : theme.palette.grey[50],
          },
        })}
      >
        <Box
          sx={theme => ({
            gap: 1,
            color: color,
            width: '100%',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            [theme.breakpoints.down('md')]: {
              gap: 0.5,
              flexDirection: 'row',
              transform: 'scale(0.9)',
            },
          })}
        >
          <Icon name={icon} size={23} color={color} />
          <Typography
            variant="caption"
            sx={{ color, fontWeight: isActive ? 600 : 400 }}
          >
            {text}
          </Typography>
        </Box>
      </Box>
    </Link>
  );

  if (badgeContent) {
    return (
      <a>
        <Badge
          badgeContent={badgeContent}
          color="error"
          sx={{
            display: 'block',
            flexShrink: 'initial',
            width: '100%',
            zIndex: 10,
            '.MuiBadge-badge': { right: isMobile ? 20 : 0 },
          }}
        >
          <NavLink />
        </Badge>
      </a>
    );
  } else {
    return <NavLink />;
  }
};

export default NavbarItem;
