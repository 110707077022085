import { FormGroup, Stack, styled } from '@mui/material';
import Switch from '@mui/material/Switch';
import React from 'react';

interface CustomizedSwitchProps {
  checked: boolean;
  onChange: (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean,
  ) => void;
}

const AntSwitch = styled(Switch)(({ theme }) => ({
  width: 44,
  height: 22,
  padding: 0,
  display: 'flex',
  '&:active': {
    '& .MuiSwitch-thumb': {
      width: 22,
    },
    '& .MuiSwitch-switchBase.Mui-checked': {
      transform: 'translateX(16px)',
    },
  },
  '& .MuiSwitch-switchBase': {
    padding: 1,
    '&.Mui-checked': {
      transform: 'translateX(22px)',
      color: theme.palette.common.white,
      transition: theme.transitions.create(['transform', 'background-color'], {
        duration: 300,
        easing: theme.transitions.easing.sharp,
      }),
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: theme.palette.primary.main,
        transition: theme.transitions.create(['background-color'], {
          duration: 300,
          easing: theme.transitions.easing.easeInOut,
        }),
      },
    },
  },
  '& .MuiSwitch-thumb': {
    boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
    width: 20,
    height: 20,
    borderRadius: 20,
    transition: theme.transitions.create(['width', 'transform'], {
      duration: 300,
      easing: theme.transitions.easing.easeInOut,
    }),
  },
  '& .MuiSwitch-track': {
    borderRadius: 22 / 2,
    opacity: 1,
    backgroundColor: theme.palette.grey[300],
    boxSizing: 'border-box',
    transition: theme.transitions.create(['background-color'], {
      duration: 300,
      easing: theme.transitions.easing.easeInOut,
    }),
  },
}));

const SwitchComponent: React.FC<CustomizedSwitchProps> = ({
  checked,
  onChange,
}) => {
  return (
    <FormGroup>
      <Stack direction="row" spacing={1} alignItems="center">
        <AntSwitch
          checked={checked}
          onChange={onChange}
          inputProps={{ 'aria-label': 'ant design' }}
        />
      </Stack>
    </FormGroup>
  );
};

export default SwitchComponent;
