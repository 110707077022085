import { Box, FormControlLabel, Switch, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useParams, useNavigate } from 'react-router-dom';
import { useLocalStorage } from 'usehooks-ts';

import { ModalsContext, ModalsContextIds } from 'context';
import { isFollowZoneEnabled, isMobileDownloadEnabled } from 'flags';
import { LocalStorageFollowedZone, LocalStorageKeys } from 'types';
import { parseBBOX } from 'utils';
import { CriticalNotificationsBanner } from '~/components/Marketing/CriticalNotificationsBanner';

import { MiscZoneInfo } from './MiscZoneInfo';
import { PointsOfInterestContainer } from './PointsOfInterest';
import { StatusDescription } from './StatusDescription';
import { ReactComponent as NoLocationFoundIcon } from '../../assets/icons/no-location-found.svg';
import { MaxAllowedNumberOfZonesToFollow, NotFound } from '../../constants';
import { useIsMobileDevice } from '../../hooks';
import { I18n } from '../../i18n';
import { Card } from '../../layouts/Card';
import { Page } from '../../layouts/Page';
import { useAppSelector, useFetchZoneDetailsQuery } from '../../redux-rtk';
import {
  AppActions,
  LocationSelectionType,
} from '../../redux-rtk/slices/appSlice';
import { ZoneLocationIcon } from '../Icon/ZoneLocationIcon';
import { ZoneName } from '../Map/MapCards/ZoneCard/ZoneName';
import { ZoneStatus } from '../Map/MapCards/ZoneCard/ZoneStatus';
import { MapPreview } from '../MapPreview';
import { Loader } from '../UI';

export const ZoneDetails = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { id: zoneId } = useParams();
  const isMobile = useIsMobileDevice();
  const { setCurrentOpenedModal } = useContext(ModalsContext);

  const [followedZones, setFollowedZones] = useLocalStorage<
    LocalStorageFollowedZone[]
  >(LocalStorageKeys.FollowedZones, []);

  const searchTerm = new URLSearchParams(useLocation().search).get(
    'searchTerm',
  );
  const centerCoords = new URLSearchParams(useLocation().search).get('coords');

  const completedAppSetupSteps = useAppSelector(
    state => state.app.data.completedAppSetupSteps,
  );

  // Query zone details if this expected to be a valid zone
  const { data, isLoading, isError } = useFetchZoneDetailsQuery(zoneId, {
    skip: (zoneId === NotFound)
  });

  const zone = data?.zone;

  const onZoneChosen = (chosenZone) => {
    navigate(`/zones/${chosenZone.identifier}`);
  }

  const handleSplitMergedZones = (contextId) => {
    setCurrentOpenedModal(contextId, {
      zones: data.history.children, 
      type: data.history.type, 
      onZoneChosen,
    })
  };

  useEffect(() => {
    if ( !data || !data.history?.type){
      return
    }

    if (data.history.type === 'split') {
      handleSplitMergedZones(ModalsContextIds.SplitSearch);
      return;
    }

    if (data.history.type === 'merged') {
      handleSplitMergedZones(ModalsContextIds.MergedSearch);
      return;
    }
  
  }, [data]);

  useEffect(() => {
    if (zone) {
        const position = centerCoords
        ? [
            Number(centerCoords.split(',')[0]),
            Number(centerCoords.split(',')[1]),
          ]
        : [zone.latitude, zone.longitude];

      dispatch(
        AppActions[
          completedAppSetupSteps.length === 0
            ? 'setInitialLocation'
            : 'selectLocation'
        ]({
          id: zone.identifier,
          type: LocationSelectionType.Zone,
          position: position,
          details: zone,
          bbox: parseBBOX(zone.bbox),
        }),
      );
    } 
  }, [zone])

  const navigateToPreviousPage = () => {
    if (!isMobile) navigate(-1)
  }

  return (
    <Page hasBackButton onBackButtonPress={() => navigateToPreviousPage()}>
      {isError || (!isLoading && !zone) ? (
        <>
          {searchTerm && (
            <Card
              backgroundColor="grey"
              hasShadow={false}
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: 1.5,
                width: 'fit-content',
                mb: 3,
              }}
            >
              <ZoneLocationIcon role="img" aria-hidden="true"/>
              <Typography variant="body2">{searchTerm}</Typography>
            </Card>
          )}
          <Card hasShadow={false} backgroundColor="grey">
            <Typography
              variant="h2"
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: 1.5,
                my: 1.5,
              }}
            >
              <NoLocationFoundIcon width="36px" height="36px" role="img" aria-hidden="true"/>
              {I18n.t('search.noZoneFound')}
            </Typography>
            <Typography variant="body2">
              {zoneId === NotFound
                ? I18n.t('search.notFound', { appName: I18n.t("common.appName") })
                : I18n.t('search.invalidZoneId')}
            </Typography>
          </Card>
        </>
      ) : isLoading ? (
        <Loader />
      ) : (
        <Box
          sx={{
            gap: 3,
            display: 'flex',
            position: 'relative',
            flexDirection: 'column',
          }}
        >
          <ZoneName zone={zone} />

          {isMobile && isFollowZoneEnabled && (
            <FormControlLabel
              value="start"
              sx={{ position: 'absolute', top: -4, right: -10 }}
              componentsProps={{
                typography: {
                  variant: 'body2',
                  color: 'text.secondary',
                },
              }}
              control={
                <Switch
                  sx={{ mr: 2 }}
                  color="primary"
                  checked={!!followedZones.find(el => el.zoneId === zoneId)}
                  onChange={(e, checked) => {
                    if (!checked) {
                      return setFollowedZones(
                        followedZones.filter(el => el.zoneId !== zoneId),
                      );
                    }

                    if (
                      followedZones.length === MaxAllowedNumberOfZonesToFollow
                    ) {
                      return enqueueSnackbar(
                        I18n.t('followZoneModal.maxFollowedZonesReached', {
                          number: MaxAllowedNumberOfZonesToFollow,
                        }),
                        {
                          variant: 'error',
                        },
                      );
                    }

                    return setCurrentOpenedModal(ModalsContextIds.FollowZone, {
                      zoneId,
                    });
                  }}
                  inputProps={{ 'aria-label': 'Follow or unfollow zone' }}
                />
              }
              labelPlacement="start"
              label={
                !followedZones.find(el => el.zoneId === zoneId)
                  ? I18n.t('zoneDetails.followZone')
                  : I18n.t('zoneDetails.followingZone')
              }
            />
          )}

          {searchTerm && (
            <Card
              backgroundColor="grey"
              hasShadow={false}
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: 1.5,
                width: 'fit-content',
              }}
            >
              <ZoneLocationIcon role="img" aria-hidden="true"/>
              <Typography variant="body2">{searchTerm}</Typography>
            </Card>
          )}

          <ZoneStatus zone={zone} />

          <StatusDescription zone={zone} />

          {isMobileDownloadEnabled && <CriticalNotificationsBanner /> }

          <MapPreview />

          <PointsOfInterestContainer zoneId={zoneId} />

          <MiscZoneInfo zone={zone} />
        </Box>
      )}
    </Page>
  );
};
