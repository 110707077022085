import { Box } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useCallback, useState } from 'react';
import { ActionMeta, components, InputActionMeta, ValueContainerProps } from 'react-select';
import AsyncSelect from 'react-select/async';

import { Loader } from '~/components/Loader';
import { isZoneId } from '~/utils';

import { getLocations } from '../../api';
import Icon from '../../components/Icon';
import { Color } from '../../constants';
import { I18n } from "../../i18n";
import {
  useAppSelector,
} from '../../redux-rtk';

interface SearchProps {
  onInputSelect: (value: any, action: ActionMeta<any>) => void,
  onInputValueChange?: (value: string, action: InputActionMeta) => void,
  placeHolderText?: string,
}

const ValueContainer = ({ children, ...props }: ValueContainerProps<any>) => {
  return (
    components.ValueContainer && (
      <components.ValueContainer {...props}>
        {!!children && <Icon name="Search" color={Color.DarkBlue} size={17} />}
        <form id="search">
          {(children as any).map((child) => {
            if (child?.key === 'placeholder') {
              return <label htmlFor='react-select-3-input' className='search-zone__placeholder'>{child.props.children}</label>
            } else {
              return child;
            }
          })}
        </form>
      </components.ValueContainer>
    )
  );
};

/**
 * 
 * @prop  onInputSelect: provides the selected address.
 * @prop onInputValueChange: provides the search suggestions.
 */

const SearchAddressOrZone = ({ onInputSelect, onInputValueChange, placeHolderText }: SearchProps): JSX.Element => {
  const [inputValue, setInputValue] = useState('');
  const [toMenuIsOpen, setToMenuIsOpen] = useState(false);

  const theme = useTheme();

  // Get the map's lng / lat as a comma-separated string. We use this as a bias when searching, and
  // also as a cache option for the AsyncSelect, so that it forces a refresh when these change
  const mapCenter = useAppSelector(state => state.map.data.viewport.center);


  const handleInputValueChange = (value: string, action: InputActionMeta) => {
    if (action.action !== 'input-blur' && action.action !== 'menu-close') {
      setInputValue(value);
    }

    setToMenuIsOpen(!!value.trim().length);
    if (onInputValueChange) {
      onInputValueChange(value, action);
    }
  }

  const handleInputSelection = useCallback((value: any, action: ActionMeta<any>) => {
    if (action.action === 'clear') {
      return setInputValue('');
    }
    onInputSelect(value, action);
  }, []);


  return (
    <Box
      sx={{
        width: '100%',
        mb: 3,
        '& .sb-icon-div': {
          position: 'absolute',
          left: '10px',
        },
      }}
      role="search"
    >
      <AsyncSelect
        className="search-zone"
        classNamePrefix="search-zone"
        isClearable
        menuIsOpen={toMenuIsOpen}
        autoFocus
        inputValue={inputValue}
        onInputChange={handleInputValueChange}
        onChange={handleInputSelection}
        isSearchable={true}
        cacheOptions={mapCenter}
        tabSelectsValue
        loadOptions={async searchText => {
          // TODO: Move this to RTK
          const locations = await getLocations(searchText, mapCenter);
          const options = locations.map(location => {
            return {
              value: location.magicKey,
              label: location.text,
            };
          });
          // Special case; if the user has entered what looks to be a valid zone identifier, we prepend that to the list
          if (isZoneId(searchText)) {
            return [
              {
                value: searchText,
                label: `${I18n.t('zones.zone')}: ${searchText.toUpperCase()}`,
              },
            ].concat(options);
          }
          return options;
        }}
        placeholder={placeHolderText}
        styles={{
          valueContainer: base => ({
            ...base,
            paddingLeft: 38,
            height: '42px',
            "& > form": {
              display: 'flex',
              alignItems: 'center',
              '& > .search-zone__placeholder': {
                color: 'rgb(128, 128, 128)',
                marginLeft: '2px'
              },
              '& > .search-zone__input-container': {
                position: 'absolute',
              }
            }
          }),
          control: (base, state) => {
            return {
              ...base,
              bottom: state.menuIsOpen ? '-2px' : '0px',
              border: state.menuIsOpen
                ? '2px solid'
                : `1px solid ${theme.palette.grey[300]}`,
              borderRadius: state.menuIsOpen ? '30px 30px 0px 0px' : '30px',
              borderColor: state.isFocused
                ? state.menuIsOpen
                  ? `${theme.palette.primary.light} ${theme.palette.primary.light} ${theme.palette.grey[50]} ${theme.palette.primary.light} !important`
                  : theme.palette.primary.light
                : theme.palette.primary.light,
              '&:hover': {
                borderColor: state.isFocused
                  ? state.menuIsOpen
                    ? `${theme.palette.primary.light} ${theme.palette.primary.light} ${theme.palette.grey[50]} ${theme.palette.primary.light}`
                    : theme.palette.primary.light
                  : theme.palette.primary.light,
              },
              paddingLeft: 5
            };
          },
          menu: base => ({
            ...base,
            borderRadius: '0px 0px 30px 30px',
            marginTop: '0px',
            borderColor: theme.palette.primary.light,
            borderStyle: 'solid',
            borderWidth: '0px 2px 2px 2px',
          }),
          input: base => ({
            ...base,
            display: 'flex',
          }),
          menuList: base => ({
            ...base,
            marginBottom: '25px',
          }),
        }}
        components={{
          DropdownIndicator: () => null,
          IndicatorSeparator: () => null,
          LoadingIndicator: () => <Loader />,
          ValueContainer,
        }}
      />
    </Box>
  );
};

export default SearchAddressOrZone;
