import { Box } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { paramsToObject } from 'utils/params';
import { SearchInput } from '~/components/BasicSearch/index';

import { HazardsList } from '../../containers/Hazards/HazardsList';
import { HazardsSortPanel } from '../../containers/Hazards/HazardsSortPanel';
import { I18n } from '../../i18n';
import { Content } from '../../layouts/Content';
import { Page } from '../../layouts/Page';
import { useAppDispatch, useAppSelector } from '../../redux-rtk';
import { AppActions, HazardsSortMethod } from '../../redux-rtk/slices/appSlice';


export const Hazards = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [sortPanelOpen, setSortPanelOpen] = useState<boolean>(false);
  const sortMethod = useAppSelector(state => state.app.data.selectedHazardSort);
  const hazardSearchTerm = useAppSelector(state => state.app.data.hazardSearchTerm);
  const dispatch = useAppDispatch();

  const hazardList = useRef<HTMLButtonElement>(null);
  const hazardSortModal = useRef(null);

  useEffect(() => {
    // The first time we load the hazards page, update the app's selected sort based on query
    // params, if applicable
    const querySortMethod = searchParams.get('sort');
    const queryHazardSearch = searchParams.get('search');
    if (
      Object.values(HazardsSortMethod).includes(
        querySortMethod as HazardsSortMethod,
      )
    ) {
      dispatch(
        AppActions.selectHazardSort(querySortMethod as HazardsSortMethod),
      );
    }
    if (queryHazardSearch) {
      dispatch(
        AppActions.updateHazardSearchTerm(queryHazardSearch),
      );
    }
  }, []);

  // TODO: Remove sort method from redux store. URL is already storing the sort method.
  useEffect(() => {
    const updateParameters: { sort?: HazardsSortMethod, search?: string } = { search: "" };
    if (sortMethod) {
      updateParameters.sort = sortMethod;
    }
    if (hazardSearchTerm) {
      updateParameters.search = hazardSearchTerm;
    }
    setSearchParams({ ...paramsToObject(searchParams), ...updateParameters });
  }, [sortMethod, hazardSearchTerm]);

  // Anytime we toggle the hazard sort modal to be visible or hidden, update the focus programmatically
  useEffect(() => {
    if (sortPanelOpen) {
      hazardSortModal?.current?.firstElementChild?.focus?.();
    } else {
      hazardList?.current?.focus?.();
    }
  }, [sortPanelOpen]);

  const handleFireSearch = (inputValue: string) => {
    dispatch(
      AppActions.updateHazardSearchTerm(inputValue),
    )
  }

  return (
    <Page title={I18n.t('fires.title')} subtitle={I18n.t('fires.disclaimer')}>
      <Box sx={{
        marginBottom: '35px'
      }}>
        <SearchInput handleSearch={handleFireSearch} initialSearchTerm={hazardSearchTerm} placeHolder={I18n.t('fires.searchByFire')} />
      </Box>
      <Content>
        <HazardsList
          onOpenSortPanel={() => setSortPanelOpen(!sortPanelOpen)}
          ref={hazardList}
        />
      </Content>

      {sortMethod && (
        <HazardsSortPanel
          isOpen={sortPanelOpen}
          onClose={() => setSortPanelOpen(false)}
          ref={hazardSortModal}
        />
      )}
    </Page>
  );
};
