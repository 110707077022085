import { Box, IconButton, Modal, Typography } from '@mui/material';
import React from 'react';

import Icon from '~/components/Icon';
import { theme } from '~/theme';

import { I18n } from '../../i18n';

const modalContent = {
  [I18n.t('fireDetails.contained')]: {
    title: I18n.t('fireDetails.disclaimers.containedDisclaimerTitle'),
    message: I18n.t('fireDetails.disclaimers.containedDisclaimerMessage'),
    iconName: 'Fire',
  },
  [I18n.t('fireDetails.dataSources')]: {
    title: I18n.t('fireDetails.disclaimers.dataSourceDisclaimerTitle'),
    message: I18n.t('fireDetails.disclaimers.dataSourceDisclaimerMessage'),
    iconName: 'DataSources',
  },
};

interface DisclaimerModalProps {
  modalType: string | null;
  handleModalClose: () => void;
}

const DisclaimerModal: React.FC<DisclaimerModalProps> = ({
  modalType,
  handleModalClose,
}) => {
  return (
    <Modal open={!!modalType} onClose={handleModalClose}>
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 300,
          padding: theme.spacing(3, 2),
          bgcolor: theme.palette.common.white,
          gap: theme.spacing(1),
          borderRadius: theme.spacing(1),
        }}
      >
        <IconButton
          onClick={handleModalClose}
          aria-label="close"
          sx={{ position: 'absolute', top: 8, right: 8 }}
        >
          <Icon name="Close" size={16} color={theme.palette.secondary.main} />
        </IconButton>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          <Icon
            name={modalContent[modalType]?.iconName}
            size={16}
            color={theme.palette.secondary.main}
          />
          <Typography
            id="modal-modal-title"
            variant="h3"
            sx={{ textTransform: 'none' }}
          >
            {modalContent[modalType]?.title}
          </Typography>
        </Box>
        <Typography
          variant="body2"
          id="modal-modal-description"
          sx={{ lineHeight: 1.5, marginTop: 1 }}
        >
          {modalContent[modalType]?.message}
        </Typography>
      </Box>
    </Modal>
  );
};

export default DisclaimerModal;
