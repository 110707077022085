import { Box, Button, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { TextButton } from '~/components/common/Buttons';
import Icon from '~/components/Icon';
import { Card } from '~/layouts/Card';
import { useAppSelector, useFetchZoneDetailsQuery } from '~/redux-rtk';
import { theme } from '~/theme';
import { PropsWithSx } from '~/utils';

import { I18n } from '../../i18n';

type props = {
  zoneId?: string | number;
  statusReason?: string;
  status?: string;
  statusColor?: string;
  clickOnView?: () => void;
};
function AlertCard(zoneDetails: props) {
  return (
    <Card
      sx={{
        padding: '20px 20px 0px 20px',
        display: 'flex',
        alignItems: 'left',
        flexDirection: 'column',
        gap: '10px',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-start',
          gap: theme.spacing(2),
        }}
      >
        <Icon
          name={'StatusDot'}
          color={zoneDetails?.statusColor}
          size={20}
        ></Icon>
        <Typography variant="body1" fontWeight={'bold'}>
          {zoneDetails?.status}
        </Typography>
      </Box>
      {zoneDetails?.statusReason && (
        <Typography
          variant="body2"
          overflow={'hidden'}
          textOverflow={'ellipsis'}
          lineHeight={'16px'}
        >
          {zoneDetails?.statusReason}
        </Typography>
      )}
      <TextButton
        sx={{
          fontWeight: 'bold',
          alignSelf: 'flex-end',
        }}
        label={I18n.t('search.viewAlert')}
        onClick={() => {
          zoneDetails?.clickOnView?.();
        }}
        isEndIconVisible={true}
        endIcon={
          <Icon
            name="ArrowSmallRight"
            size={25}
            color={theme.palette.primary.main}
          ></Icon>
        }
      ></TextButton>
    </Card>
  );
}

export default AlertCard;
