import { Box, Typography } from '@mui/material';
import React from 'react';

interface DataSourcesProps {
  dataSources: string[];
}

const DataSources: React.FC<DataSourcesProps> = ({ dataSources }) => {
  return (
    <Box sx={{ display: 'flex', flexWrap: 'wrap', width: '100%', gap: 0.5 }}>
      {dataSources?.map((dataSource: string, index: number) => {
        return (
          <Typography variant="body1">
            {dataSource}
            {index < dataSources.length - 1 && ','}
          </Typography>
        );
      })}
    </Box>
  );
};

export default DataSources;
