import { Box, Pagination, styled } from '@mui/material';
import { FC, useState } from 'react';

import { Color } from '~/constants';
import { AlertCardData } from '~/pages/AlertsList/types';
import { extractDateTime } from '~/utils/alertsUtils';

import ActiveAlertsCard from '../ActiveAlertsCard';

// to be removed if pagination is not required
const StyledPagination = styled(Pagination)(({ theme }) => ({
  '& .MuiPaginationItem-root': {
    borderRadius: theme.spacing(1),
  },
  '& .Mui-selected': {
    backgroundColor: Color.White,
    border: `1px solid ${theme.palette.primary.main}`,
    color: theme.palette.primary.main,
    '&:hover': {
      borderColor: theme.palette.primary.main,
      backgroundColor: Color.White,
    },
  },
}));
interface ActiveAlertListProps {
  cardsData: AlertCardData[];
}

const ActiveAlertList: FC<ActiveAlertListProps> = ({ cardsData }) => {
  const [page, setPage] = useState(1);
  const cardsPerPage = 3;
  const handleChange = (event, value) => {
    setPage(value);
  };

  const paginatedCards = cardsData?.slice(
    (page - 1) * cardsPerPage,
    page * cardsPerPage,
  );
  return (
    <Box>
      {paginatedCards?.map(card => {
        const [date, time] = extractDateTime(card.lastUpdated);
        return (
          <ActiveAlertsCard
            key={card.id}
            id={card.id}
            title={card.title?.en}
            description={card.description?.en}
            footerData={{
              critical: card.critical,
              county: card.county,
              date: date,
              time: time,
            }}
            sx={{
              borderBottom: `1px solid ${Color.AntiFlashWhite}`,
              borderRadius: 0,
            }}
          />
        );
      })}
      {cardsData?.length > cardsPerPage && (
        <Box mt={2} display="flex" justifyContent="center">
          <StyledPagination
            count={Math.ceil(cardsData?.length / cardsPerPage)}
            page={page}
            onChange={handleChange}
          />
        </Box>
      )}
    </Box>
  );
};

export default ActiveAlertList;
