import { Box } from '@mui/material';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import React, { Children, isValidElement } from 'react';

interface TabsContainerProps {
  tabsData: Array<{ id: string | number; label: string }>;
  onTabChange: (e, id: number | string) => void;
  activeTab: number | string;
  children: React.ReactNode;
}

const TabsComponent: React.FC<TabsContainerProps> = ({
  tabsData,
  onTabChange,
  activeTab,
  children,
}) => {
  return (
    <Box>
      <Tabs
        value={activeTab}
        onChange={onTabChange}
        variant="scrollable"
        scrollButtons
        allowScrollButtonsMobile
      >
        {tabsData.map(({ id, label }) => (
          <Tab key={id} label={label} value={id} />
        ))}
      </Tabs>
      <Box>
        {Children.map(children, child => {
          if (isValidElement(child) && child.props.selectedTab === activeTab) {
            return child;
          }
          return null;
        })}
      </Box>
    </Box>
  );
};

export default TabsComponent;
