import { I18n } from '../../../i18n';
import { Card } from '../../../layouts/Card';
import { Divider } from '../../Divider';
import { Zone } from '../../Map/types';
import { Icon } from '../../UI/Icon';
import { Title } from '../shared/Title';
import { ZoneBoundaries } from './ZoneBoundaries';
import { ZoneWeather } from './ZoneWeather';
import { OpenInNewRounded } from '@mui/icons-material';
import { Box, Typography } from '@mui/material';
import { Fragment } from 'react';
import { useFetchZoneLinksQuery } from 'redux-rtk';

interface MiscZoneInfoProps {
  zone: Zone;
}

export const MiscZoneInfo = ({ zone }: MiscZoneInfoProps) => {
  const { data: links = [] } = useFetchZoneLinksQuery(zone.identifier);

  return (
    <Card hasShadow={false} backgroundColor="grey">
      <Title
        title={I18n.t('zoneDetails.locationInformation')}
        icon={<Icon size={18} name="zone-info" isDecorative={true} />}
      />

      <ZoneBoundaries
        zoneBoundaries={zone?.boundaries?.en}
        zoneName={zone.commonlyknownas}
      />

      <ZoneWeather sx={{ mt: 2 }} location={[zone.latitude, zone.longitude]} />

      {(zone.countyWebsite || zone.countyAlerts) && (
        <Fragment>
          <Divider sx={{ my: 3 }} />

          <Title title={I18n.t('zoneDetails.communityLinks')} sx={{ mb: 1 }} />

          <Typography variant="body2" sx={{ mb: 4 }}>
            {I18n.t('zoneDetails.followSources', { name: zone.countyName })}
          </Typography>

          <Box
            sx={{
              mt: 3,
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-around',
            }}
          >
            {/* TODO: Add Twitter URL when available */}
            {/* <a
              className="link"
              target={"_blank"}
              rel="noreferrer"
              href={zone.countyWebsite}
            >
              <Icon size={20} name={"twitter"} />
              <span>Twitter</span>
            </a> */}

            {zone.countyWebsite && (
              <a
                className="link"
                target={'_blank'}
                rel="noreferrer"
                href={zone.countyWebsite}
              >
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    flexDirection: 'column',
                    justifyContent: 'center',
                  }}
                >
                  <Icon size={20} name={'web'} isDecorative={true} />
                  <Typography variant="body2" sx={{ mt: 2 }}>
                    {I18n.t('zoneDetails.website')}
                  </Typography>
                </Box>
              </a>
            )}

            {zone.countyAlerts && (
              <a
                className="link"
                target={'_blank'}
                rel="noreferrer"
                href={zone.countyAlerts}
              >
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    flexDirection: 'column',
                    justifyContent: 'center',
                  }}
                >
                  <Icon size={20} name={'alerts'} isDecorative={true} />
                  <Typography variant="body2" sx={{ mt: 2 }}>
                    {I18n.t('zoneDetails.alerts')}
                  </Typography>
                </Box>
              </a>
            )}
          </Box>
        </Fragment>
      )}

      {links.length > 0 && (
        <Fragment>
          <Divider sx={{ my: 3 }} />
          <Title title={'Other links'} />

          <Box
            sx={{
              mt: 3,
              gap: [3, 3, 2],
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            {links.map(link => (
              <a
                key={link.url}
                className="link"
                target={'_blank'}
                rel="noreferrer"
                href={link.url}
              >
                <Box
                  sx={{
                    gap: 1,
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                  }}
                >
                  <OpenInNewRounded
                    sx={{ height: 16, width: 16 }}
                    role="img"
                    titleAccess="Opens in a new tab"
                  />
                  <Typography variant="body1">{link.name}</Typography>
                </Box>
              </a>
            ))}
          </Box>
        </Fragment>
      )}
    </Card>
  );
};
