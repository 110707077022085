import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import de from './locales/de.json';
import en from './locales/en.json';
import es from './locales/es.json';
import fr from './locales/fr.json';
import ja from './locales/ja.json';
import sl from './locales/sl.json';

// Import other languages as needed

// Create a new i18n instance
const alertsI18n = i18n.createInstance();

alertsI18n.use(initReactI18next).init({
  resources: {
    en: { translation: en },
    es: { translation: es },
    de: { translation: de },
    fr: { translation: fr },
    ja: { translation: ja },
    sl: { translation: sl },
    // Add other languages here
  },
  lng: 'en', // Default language for the component
  fallbackLng: 'en',
  debug: false,

  interpolation: {
    escapeValue: false,
  },
});

export { alertsI18n };
