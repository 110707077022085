export const extractDateTime = (isoString: string) => {
  const date = new Date(isoString);

  const month = String(date.getUTCMonth() + 1).padStart(2, '0');
  const day = String(date.getUTCDate()).padStart(2, '0');
  const year = date.getUTCFullYear();
  const hours = String(date.getUTCHours()).padStart(2, '0');
  const minutes = String(date.getUTCMinutes()).padStart(2, '0');
  const seconds = String(date.getUTCSeconds()).padStart(2, '0');

  // Format the date and time
  const formattedDate = `${month}-${day}-${year}`;
  const formattedTime = `${hours}:${minutes}:${seconds}`;

  return [formattedDate, formattedTime];
};

export const getNativeLanguageNames = languageCodes => {
  return languageCodes.map(code => {
    const displayNames = new Intl.DisplayNames([code], { type: 'language' });
    return {
      languageCode: code,
      displayName: displayNames.of(code),
    };
  });
};
