import { Box, Typography, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';

import Icon from '~/components/Icon';
import SelectComponent from '~/components/SelectComponent/SelectComponent';
import { Color } from '~/constants';
import { I18n } from '~/i18n';
import { extractDateTime } from '~/utils/alertsUtils';
import '~/i18n/alertsI18n';

const AlertDetails = ({ alertDetails, availableLanguages }) => {
  const theme = useTheme();
  const { t, i18n } = useTranslation();

  const [date, time] = extractDateTime(alertDetails?.lastUpdated);

  const renderTranslatedText = alertData => {
    // Check if the corresponding selected langugage exists
    if (alertData?.[i18n.language]) {
      return alertData[i18n.language];
    }
    // Fallback to 'en' if the selected langugage is not present
    return alertData['en'];
  };
  return (
    <>
      {availableLanguages?.length > 1 && (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            color: theme.palette.secondary.main,
            width: '164px',
            gap: 1,
          }}
        >
          <Typography variant="body2" sx={{ color: theme.palette.grey[600] }}>
            {I18n.t('alertsList.selectLanguageHeader')}
          </Typography>
          <SelectComponent
            options={availableLanguages}
            selectedValue={i18n.language}
            onChange={e => {
              i18n.changeLanguage(String(e.target.value));
            }}
            icon={
              <Icon
                name="World"
                size={16}
                color={theme.palette.secondary.main}
              />
            }
          />
        </Box>
      )}
      {alertDetails?.status === 'expired' && (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: theme.spacing(0.5),
            margin: theme.spacing(2, 0),
          }}
        >
          <Icon name="InformationFilled" size={14} color={Color.NeutralGrey} />
          <Typography variant="body2" sx={{ fontStyle: 'italic' }}>
            {t('alertsList.expiredAlertWarning')}
          </Typography>
        </Box>
      )}
      {Object.keys(alertDetails?.title || {}).length > 0 && (
        <Typography variant="h2" sx={{ textTransform: 'none' }}>
          {renderTranslatedText(alertDetails?.title)}
        </Typography>
      )}
      {alertDetails?.lastUpdated && (
        <Box
          sx={{
            display: 'flex',
            gap: theme.spacing(2),
            margin: theme.spacing(1, 0),
          }}
        >
          <Box sx={{ display: 'flex', gap: theme.spacing(1) }}>
            <Typography variant="body1" sx={{ color: Color.NeutralGrey }}>
              {t('alertsList.headerTexts.date')}
            </Typography>
            <Typography variant="body1">{date}</Typography>
          </Box>
          <Box sx={{ display: 'flex', gap: theme.spacing(1) }}>
            <Typography variant="body1" sx={{ color: Color.NeutralGrey }}>
              {t('alertsList.headerTexts.time')}
            </Typography>
            <Typography variant="body1">{time}</Typography>
          </Box>
        </Box>
      )}
      <Box sx={{ margin: theme.spacing(2, 0) }}>
        {Object.keys(alertDetails?.reason || {}).length > 0 && (
          <Box
            sx={{
              backgroundColor: theme.palette.grey[50],
              padding: theme.spacing(2),
            }}
          >
            <Typography variant="h3" sx={{ marginBottom: theme.spacing(2) }}>
              {t('alertsList.headerTexts.reason')}
            </Typography>
            <Typography variant="body1">
              {renderTranslatedText(alertDetails?.reason)}
            </Typography>
          </Box>
        )}
        {Object.keys(alertDetails?.description || {}).length > 0 && (
          <Box
            sx={{
              backgroundColor: theme.palette.grey[50],
              padding: theme.spacing(2),
            }}
          >
            <Typography variant="h3" sx={{ marginBottom: theme.spacing(2) }}>
              {t('alertsList.headerTexts.description')}
            </Typography>
            <Typography variant="body1">
              {renderTranslatedText(alertDetails?.description)}
            </Typography>
          </Box>
        )}
      </Box>
      {alertDetails?.zonesImpacted?.length > 0 && (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            backgroundColor: theme.palette.grey[50],
            padding: theme.spacing(2),
            margin: theme.spacing(1, 0),
            gap: theme.spacing(2),
          }}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: theme.spacing(1),
              }}
            >
              <Icon
                name="Warning"
                size={18}
                color={theme.palette.secondary.main}
              />
              <Typography variant="h3" sx={{ fontSize: '14px' }}>
                {t('alertsList.headerTexts.zonesAffected')}
              </Typography>
            </Box>
            <Typography variant="body1">
              {alertDetails?.zonesImpacted?.length}
            </Typography>
          </Box>
          {alertDetails?.zonesImpacted?.map(zone => (
            <Box
              sx={{
                borderRadius: theme.spacing(1),
                padding: theme.spacing(0.5, 1),
                gap: theme.spacing(1),
                backgroundColor: theme.palette.common.white,
              }}
            >
              <Typography variant="body1">{zone}</Typography>
            </Box>
          ))}
        </Box>
      )}
      {alertDetails?.critical && (
        <Box
          sx={{
            backgroundColor: theme.palette.grey[50],
            padding: theme.spacing(2),
            margin: theme.spacing(1, 0),
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <Box sx={{ display: 'flex', gap: theme.spacing(1) }}>
            <Icon name="Flag" size={20} color={theme.palette.secondary.main} />
            <Typography variant="h3" sx={{ fontSize: '14px' }}>
              {t('alertsList.headerTexts.severity')}
            </Typography>
          </Box>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              padding: '4px 8px',
              borderRadius: theme.spacing(3),
              gap: theme.spacing(0.5),
              background: Color.WhiteSmoke,
              border: `1px solid ${Color.PaleRed}`,
            }}
          >
            <Icon name="Warning" size={12} color={Color.DarkRed} />
            <Typography
              sx={{ fontSize: 12, fontWeight: 600, color: Color.DarkRed }}
            >
              {t('alertsList.headerTexts.critical')}
            </Typography>
          </Box>
        </Box>
      )}
      {alertDetails?.county && (
        <Box
          sx={{
            backgroundColor: theme.palette.grey[50],
            padding: theme.spacing(1),
            margin: theme.spacing(2, 0),
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <Box sx={{ display: 'flex', gap: theme.spacing(1) }}>
            <Icon
              name="County"
              size={20}
              color={theme.palette.secondary.main}
            />
            <Typography variant="h3" sx={{ fontSize: '14px' }}>
              {t('alertsList.headerTexts.county')}
            </Typography>
          </Box>
          <Typography variant="body1">{alertDetails?.county}</Typography>
        </Box>
      )}
    </>
  );
};

export default AlertDetails;
