/* eslint-disable complexity */
import { Box } from '@mui/material';
import centerOfMass from '@turf/center-of-mass';
import { BBox, Feature, Geometry, Position } from 'geojson';
import { FlyToOptions, LngLatBounds, LngLatLike, Offset } from 'maplibre-gl';
import 'maplibre-gl/dist/maplibre-gl.css';
import {
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { AttributionControl } from 'react-map-gl';
import {
  GeolocateControl,
  Layer,
  Map as MapComponent,
  MapGeoJSONFeature,
  MapLayerMouseEvent,
  MapRef,
  Marker,
  NavigationControl,
  Popup,
  ScaleControl,
  Source,
  ViewStateChangeEvent,
} from 'react-map-gl/maplibre';
import {
  useLocation,
  useMatch,
  useNavigate,
  useSearchParams,
} from 'react-router-dom';
import { useReadLocalStorage } from 'usehooks-ts';

import {
  trackUserClickedMapEvacuationCenter,
  trackUserClickedMapFire,
  trackUserClickedMapZone,
} from '~/analytics/fns';
import AnimalShelterMapIcon from '~/assets/icons/animal-shelter.png';
import EvacuationCentersIcon from '~/assets/icons/evacuation-centers.png';
import MarkerFireIcon from '~/assets/icons/fire.png';
import RallyPointsIcon from '~/assets/icons/rally-points.png';
import RoadClosureIcon from '~/assets/icons/road-closures.png';
import TrafficControlPointIcon from '~/assets/icons/traffic-control-points.png';
import { UserLocationIcon } from '~/components/Icon/UserLocationIcon';
import { ZoneCard } from '~/components/Map/MapCards/ZoneCard/ZoneCard';
import { MarkersNames } from '~/constants/markers';
import { isFollowZoneEnabled } from '~/flags';
import { useIsMobileDevice } from '~/hooks';
import { useGetSelectedApTcpData } from '~/hooks/components/Map/useGetSelectedApTcpData';
import { useUserLocation } from '~/hooks/components/Map/useUserLocation';
import {
  MapActions,
  useAppDispatch,
  useAppSelector,
  UserActions,
} from '~/redux-rtk';
import {
  AppActions,
  AppSetupSteps,
  LocationSelection,
  LocationSelectionType,
} from '~/redux-rtk/slices/appSlice';
import { LocalStorageKeys, LocationOrigin } from '~/types';
import {
  checkTopFeatureIsAlert,
  geoserver,
  getApOrTcpFromMapData,
  maplibre,
  parseBBOX,
  PropsWithSx,
  setFeatureStateForPoint,
} from '~/utils';
import { paramsToObject } from '~/utils/params';

import { FireIcon } from '../Icon/FireIcon';
import { ZoneLocationIcon } from '../Icon/ZoneLocationIcon';
import {
  AlertId,
  appMapConfig,
  ArrivalPointId,
  controlConfig,
  FireId,
  getMapLayers,
  mapTiles,
  TrafficControlPointId,
  ZoneId,
} from './config';
import { LayersWrapper } from './Layers';
import { LayerHighlightLine } from './Layers/LayerHighlightZone';
import { ApTcpCard } from './MapCards/ApTcpCard';
import { HazardCard } from './MapCards/HazardCard';
import './mapControlsLayout.css';
import './resetMapStyles.css';
import { AlertFromALERTSLayer } from './mockDetails';

interface MapProps {
  hasPopups?: boolean;
  hasZoomControls?: boolean;
}

// const mapStyle = getMapStyles();

const layers = getMapLayers();

export const Map = ({
  sx,
  hasPopups = true,
  hasZoomControls = true,
}: PropsWithSx<MapProps>): ReactElement => {
  const navigate = useNavigate();

  const location = useLocation();

  const dispatch = useAppDispatch();

  const preferredOrigin = useReadLocalStorage<LocationOrigin>(
    LocalStorageKeys.PreferredOrigin,
  );

  const isMobile = useIsMobileDevice();

  const [searchParams, setSearchParams] = useSearchParams();

  const isViewingZoneDetails = useMatch('/zones/:id');

  const selectedLocation = useAppSelector(
    state => state.app.data.selectedLocation,
  );

  const completedAppSetupSteps = useAppSelector(
    state => state.app.data.completedAppSetupSteps,
  );

  const mapRef = useRef<MapRef>();

  const [isMapLoaded, setIsMapLoaded] = useState(false);

  const { userAccuracyCircle, userPosition } = useUserLocation({ isMapLoaded });

  useEffect(() => {
    const TimeoutMs = 100;

    if (!selectedLocation || !isMapLoaded) {
      return;
    }

    if (selectedLocation?.bbox) {
      // /Delay the fitBounds to the event phase to give the map enough time to update all layers
      const padding = !isMobile
        ? 200
        : !selectedLocation.settings.hasPopup
        ? 80
        : 140;

      setTimeout(
        () =>
          mapRef.current?.fitBounds(selectedLocation?.bbox, {
            padding,
            animate: selectedLocation.settings?.hasFlyToAnimation ?? true,
            duration: selectedLocation.settings?.hasFlyToAnimation
              ? appMapConfig.flyTime
              : undefined,
          }),
        TimeoutMs,
      );
      return;
    }

    const center = [
      selectedLocation.position[1],
      selectedLocation.position[0],
    ] as LngLatLike;

    const zoom = selectedLocation?.zoom;

    if (!selectedLocation?.settings?.hasFlyToAnimation) {
      setTimeout(
        () =>
          mapRef.current?.jumpTo({
            center,
            zoom,
          }),
        TimeoutMs,
      );
      return;
    }

    const flyOptions: FlyToOptions = {
      zoom,
      center,
      duration: appMapConfig.flyTime,
    };

    setTimeout(() => mapRef.current?.flyTo(flyOptions), TimeoutMs);
  }, [selectedLocation, isMobile, isMapLoaded]);

  useEffect(() => {
    if (isViewingZoneDetails) {
      return;
    }

    const latitude = parseFloat(searchParams.get('latlon')?.split(',')[0]);
    const longitude = parseFloat(searchParams.get('latlon')?.split(',')[1]);
    const zoom = parseFloat(searchParams.get('z'));
    const hasViewportParams =
      !isNaN(latitude) && !isNaN(longitude) && !isNaN(zoom);

    if (
      hasViewportParams &&
      preferredOrigin &&
      !completedAppSetupSteps.includes(
        AppSetupSteps.SetInitialLocationSelection,
      )
    ) {
      dispatch(
        AppActions.setInitialLocation({
          type: LocationSelectionType.Position,
          position: [latitude, longitude],
          zoom,
        }),
      );
    }
  }, []);

  const onMapLoad = useCallback(() => {
    setIsMapLoaded(true);

    const { current } = mapRef;

    if (!current) {
      return;
    }

    current.getMap().touchZoomRotate.disableRotation();

    current.loadImage(MarkerFireIcon, (_, image) => {
      current.addImage(MarkersNames.Fire, image);
    });
    current.loadImage(EvacuationCentersIcon, (_, image) => {
      current.addImage(MarkersNames.EvacuationCenter, image);
    });
    current.loadImage(AnimalShelterMapIcon, (_, image) => {
      current.addImage(MarkersNames.AnimalShelter, image);
    });
    current.loadImage(RallyPointsIcon, (_, image) => {
      current.addImage(MarkersNames.RallyPoint, image);
      current.addImage(MarkersNames.OnRamp, image);
      current.addImage(MarkersNames.Shelter, image);
      current.addImage(MarkersNames.ResourceCenter, image);
    });
    current.loadImage(RoadClosureIcon, (_, image) => {
      current.addImage(MarkersNames.RoadClosure, image);
    });

    current.loadImage(TrafficControlPointIcon, (_, image) => {
      current.addImage(MarkersNames.TrafficControlPoint, image);
    });
  }, []);

  const handleCloseLocationPopup = useCallback(
    () => dispatch(AppActions.selectLocation(null)),
    [dispatch],
  );

  const handleMapClick = useCallback(
    (event: MapLayerMouseEvent) => {
      event.originalEvent.stopImmediatePropagation();
      const clickedFeature = event.features as MapGeoJSONFeature[];
      const features = clickedFeature.map(feature => {
        if (AlertId === feature.layer.id) {
          const zoneCenter = centerOfMass(
            feature.geometry,
          ).geometry.coordinates.reverse() as Position;
          return {
            id: feature.properties.identifer,
            type: LocationSelectionType.Alert,
            position: zoneCenter,
          };
        }
        if (ZoneId === feature.layer.id) {
          const zoneCenter = centerOfMass(
            feature.geometry,
          ).geometry.coordinates.reverse() as Position;

          trackUserClickedMapZone(feature.properties.identifer);

          return {
            id: feature.properties.identifer,
            type: LocationSelectionType.Zone,
            position: zoneCenter,
            bbox: parseBBOX(feature.properties.bbox),
            details: {
              status: feature.properties?.status
            }
          };
        }
        if (FireId === feature.layer.id) {
          const fireCenter = centerOfMass(
            feature.geometry,
          ).geometry.coordinates.reverse() as Position;

          trackUserClickedMapFire(feature.properties.fire_id);
          return {
            id: feature.properties.fire_id,
            type: LocationSelectionType.Hazard,
            position: fireCenter,
            bbox: parseBBOX(feature.properties.bbox),
          };
        }
        if (
          TrafficControlPointId === feature.layer.id ||
          ArrivalPointId === feature.layer.id
        ) {
          const poiCenter = centerOfMass(
            feature.geometry,
          ).geometry.coordinates.reverse() as Position;

          const id =
            feature.properties.arrival_point_id ??
            feature.properties.id;

          trackUserClickedMapEvacuationCenter(id);

          const details = getApOrTcpFromMapData(feature, { id });

          return {
            id: id,
            type: details.type as unknown as LocationSelectionType,
            position: poiCenter,
            details,
          };
        }
      })
      // need to show a 'location-icon' if there are no alerts in the clicked location or if user clicks on alerts
      if (!features.length || checkTopFeatureIsAlert(features[0])) {
        dispatch(
          AppActions.selectLocation({
            position: event.lngLat.toArray().reverse(),
            type: LocationSelectionType.Position
          })
        )
      }
      // or user clicks on ciritical-points/fires/shelter etc
      else
        dispatch(AppActions.selectLocation({ ...features[0] }));

      //featureCollection is used in /location page to show the details
      dispatch(AppActions.updateFeatureCollection([...features]))
      navigate(`/location`);
    }
    , [isViewingZoneDetails, isMobile, navigate, dispatch, selectedLocation],)

  const syncMap = useCallback(() => {
    const zoomParam = parseFloat(searchParams.get('z'));
    const isValidZoom = !isNaN(zoomParam);

    if (isValidZoom) {
      dispatch(MapActions.setZoomState(zoomParam));
    }

    if (!isMapLoaded || !mapRef.current) {
      return;
    }

    const zoom = mapRef.current.getZoom();

    const { lat, lng } = mapRef.current.getCenter();
    const center = [lat, lng];

    const bounds = mapRef.current.getBounds();

    const bbox = LngLatBounds.convert(bounds).toArray().flat() as BBox;

    dispatch(MapActions.setBboxState(bbox));

    setSearchParams(
      {
        ...paramsToObject(searchParams),
        z: zoom.toString(),
        latlon: `${center[0]},${center[1]}`,
      },
      { replace: true },
    );

    dispatch(MapActions.setCenterPositionState(center));
  }, [dispatch, isMapLoaded, searchParams, setSearchParams]);

  const onZoom = useCallback(
    (event: ViewStateChangeEvent) => {
      dispatch(MapActions.setZoomState(event.target.getZoom()));
    },
    [dispatch],
  );

  const selectedArrivalPointData = useGetSelectedApTcpData({
    selectedLocation,
    map: mapRef.current,
    type: 'arrivalPoint',
  });
  const selectedTrafficControlPointData = useGetSelectedApTcpData({
    selectedLocation,
    map: mapRef.current,
    type: 'trafficControlPoint',
  });

  useEffect(() => {
    // Anytime our selected point feature (TCP or AP) changes, we need to enlarge the matching icon.
    // We do this by setting "feature state," which is built into the MapLibre API
    const selectedPoint =
      selectedArrivalPointData ?? selectedTrafficControlPointData;
    setFeatureStateForPoint(selectedPoint, mapRef.current, true);
    return () => {
      // When it changes again, we revert the old selection
      const selectedPoint =
        selectedArrivalPointData ?? selectedTrafficControlPointData;
      setFeatureStateForPoint(selectedPoint, mapRef.current, false);
    };
  }, [selectedArrivalPointData, selectedTrafficControlPointData]);

  const initialLocation = useMemo(() => {
    // since we are mounting / unmounting the map in mobile
    // we want to set the camera to the right view as soon as possible
    // => by passing these values as props on the initial render we ensure this
    if (selectedLocation && !isMapLoaded) {
      return {
        latitude: selectedLocation.position[0],
        longitude: selectedLocation.position[1],
        zoom: selectedLocation.zoom,
      };
    }

    return {};
  }, [isMapLoaded, isMobile, selectedLocation, completedAppSetupSteps]);

  return (
    <Box sx={{ width: '100%', height: '100%', ...sx }}>
      <MapComponent
        {...initialLocation}
        ref={mapRef}
        boxZoom={true}
        cursor="pointer"
        onZoom={onZoom}
        onLoad={onMapLoad}
        refreshExpiredTiles
        key={isMobile ? location.pathname : 'stable'}
        data-testid="map-canvas"
        onMoveEnd={syncMap}
        onClick={handleMapClick}
        id={'mapContainer'}
        interactiveLayerIds={[
          ZoneId,
          FireId,
          TrafficControlPointId,
          ArrivalPointId,
          AlertId
        ]}
        mapStyle={maplibre.StreetUrl}
        minZoom={0}
        maxZoom={22}
        minPitch={0}
        maxPitch={0}
        reuseMaps={false}
        dragRotate={false}
        touchPitch={false}
        pitchWithRotate={false}
        attributionControl={false}
        transformRequest={(url: string, resourceType: string) => {
          if (resourceType === 'Tile' && url.startsWith(geoserver.WMTS)) {
            return {
              url,
              headers: {
                Authorization: `Basic ${geoserver.Creds}`,
              },
            };
          }
        }}
      >
        <LayersWrapper layers={layers} tiles={mapTiles} />

        {userAccuracyCircle ? (
          <>
            <Marker
              offset={[-3.5, 1]}
              scale={1}
              draggable={false}
              style={{
                overflow: 'visible',
                cursor: 'pointer',
              }}
              longitude={userPosition.longitude}
              latitude={userPosition.latitude}
            >
              <UserLocationIcon
                sx={{
                  boxShadow: 1,
                  color: 'white',
                  height: '16px',
                  width: '16px',
                  overflow: 'visible',
                }}
              />
            </Marker>

            <Source type="geojson" data={userAccuracyCircle}>
              <Layer
                type="fill"
                paint={{
                  'fill-color': '#0aa4e1',
                  'fill-opacity': 0.1,
                }}
              />
            </Source>
          </>
        ) : null}

        <AttributionControl
          position="bottom-right"
          customAttribution={'Powered by Esri'}
          compact={false}
        />

        {hasZoomControls && (
          <NavigationControl {...controlConfig.navigationControl} />
        )}

        <ScaleControl {...controlConfig.scaleControl} />

        <GeolocateControl
          {...controlConfig.geolocateControl}
          onGeolocate={e =>
            dispatch(
              UserActions.setLocationState({
                origin: LocationOrigin.Browser,
                coordinates: [e.coords.latitude, e.coords.longitude],
                accuracyInMeters: e.coords.accuracy,
              }),
            )
          }
        />

        {selectedLocation?.position &&
        selectedLocation?.type === LocationSelectionType.Hazard ? (
          <Marker
            longitude={selectedLocation.position[1]}
            latitude={selectedLocation.position[0]}
          >
            <FireIcon
              sx={{
                padding: '7px',
                backgroundColor: 'danger',
                borderRadius: '25px',
                color: 'white',
                border: '3px solid white',
                height: '38px',
                width: '38px',
                overflow: 'visible',
              }}
              role="img"
            />
          </Marker>
        ) : null}

        {selectedLocation?.position &&
        selectedLocation?.type === LocationSelectionType.Zone ? (
          <Marker
            anchor="top"
            offset={[0, -44]}
            longitude={selectedLocation.position[1]}
            latitude={selectedLocation.position[0]}
          >
            <ZoneLocationIcon
              sx={{
                padding: '7px',
                backgroundColor: theme => `${theme.palette.primary.dark}`,
                borderRadius: '25px',
                color: 'white',
                border: '3px solid white',
                height: '38px',
                width: '38px',
                overflow: 'visible',
              }}
              role="img"
            />
          </Marker>
        ) : null}

        {selectedLocation?.position &&
          selectedLocation?.type === LocationSelectionType.Position ? (
          <Marker
            anchor="top"
            offset={[0, -44]}
            longitude={selectedLocation.position[1]}
            latitude={selectedLocation.position[0]}
          >
            <ZoneLocationIcon
              sx={{
                padding: '7px',
                backgroundColor: theme => `${theme.palette.primary.dark}`,
                borderRadius: '25px',
                color: 'white',
                border: '3px solid white',
                height: '38px',
                width: '38px',
                overflow: 'visible',
              }}
              role="img"
            />
          </Marker>
        ) : null}
        {hasPopups && selectedLocation?.settings?.hasPopup ? (
          <>
            {selectedLocation?.type === LocationSelectionType.Zone && (
              <Popup
                offset={[0, -40] as Offset}
                closeButton={false}
                closeOnClick={false}
                anchor="bottom"
                latitude={selectedLocation.position[0]}
                longitude={selectedLocation.position[1]}
                key={`zoneinfo-popup-${selectedLocation.id}`}
                focusAfterOpen={false}
              >
                <ZoneCard
                  sx={{ width: 360 }}
                  isCloseButtonVisible
                  isFollowButtonVisible={isFollowZoneEnabled}
                  onClose={handleCloseLocationPopup}
                  zoneId={selectedLocation.id}
                />
              </Popup>
            )}

            {selectedLocation?.type === LocationSelectionType.Hazard && (
              <Popup
                offset={[0, -30] as Offset}
                closeButton={false}
                anchor="bottom"
                latitude={selectedLocation.position[0]}
                longitude={selectedLocation.position[1]}
                key={`hazardinfo-popup-${selectedLocation.id}`}
                focusAfterOpen={false}
              >
                <HazardCard
                  hasFireLabel
                  sx={{ minHeight: 120, width: 360 }}
                  onClose={handleCloseLocationPopup}
                  hazardId={selectedLocation.id}
                />
              </Popup>
            )}

            {selectedArrivalPointData && (
              <Popup
                offset={[0, -30] as Offset}
                closeButton={false}
                anchor="bottom"
                latitude={selectedLocation.position[0]}
                longitude={selectedLocation.position[1]}
                key={`arrival-point-info-popup-${selectedLocation.id}`}
              >
                <ApTcpCard
                  data={selectedArrivalPointData}
                  sx={{ minHeight: 120, width: 360 }}
                  onClose={handleCloseLocationPopup}
                  isArrivalPoint
                />
              </Popup>
            )}

            {selectedTrafficControlPointData && (
              <Popup
                offset={[0, -30] as Offset}
                closeButton={false}
                anchor="bottom"
                latitude={selectedLocation.position[0]}
                longitude={selectedLocation.position[1]}
                key={`road-closure-info-popup-${selectedLocation.id}`}
              >
                <ApTcpCard
                  data={selectedTrafficControlPointData}
                  sx={{ minHeight: 120, width: 360 }}
                  onClose={handleCloseLocationPopup}
                />
              </Popup>
            )}
          </>
        ) : null}

        {
          selectedLocation?.type === LocationSelectionType.Zone &&
          <LayerHighlightLine zoneId={selectedLocation?.id} />
        }


        //at the moment layer for alerts from 'ALERTS' is unavailable, hence the custom layer
        // only used for testing, should be removed
        <Source type="geojson" data={AlertFromALERTSLayer}>
          <Layer
            type="fill"
            id={AlertId}
            paint={{
              'fill-color': 'red',
              'fill-opacity': 0.5,
            }}
          />
        </Source>
      </MapComponent>
    </Box>
  );
};
