export const AlertFromALERTSLayer = {
    "type": "FeatureCollection",
    "features": [
        {
            "type": "Feature",
            "properties": {
                id: 50330667,
                identifer: "US-ALERT-E025",
                latitude: "37.52294849354666",
                longitude: "-122.51349164493271",
                opacity: 0.5,
            },
            "geometry": {
                "coordinates": [
                    [
                        [
                            -122.51474923060752,
                            37.528176278155044
                        ],
                        [
                            -122.51407899758314,
                            37.52703727467987
                        ],
                        [
                            -122.51262682603034,
                            37.52793582331422
                        ],
                        [
                            -122.51474923060752,
                            37.528176278155044
                        ]
                    ]
                ],
                "type": "Polygon"
            }
        }
    ]
}

export const featuredata = {
    type: "Feature",
    properties: {
        bbox: "BOX(-122.51462920037726 37.53996538410062,-122.46397800644749 37.57680744009046)",
        color: "#6A95CB",
        commonly_known_as: "McNee Ranch State Park",
        id: 50340598,
        identifer: "US-CA-XSM-SMC-E079-A",
        latitude: "37.55860222403881",
        longitude: "-122.48752912606126",
        opacity: 0.5,
        status: "Advisory",
    },
    id: 50340598,
    layer: {
        id: "evacuation_zone",
        type: "fill",
        source: "evacuation_zone",
        "source-layer": "evacuation_zone",
        minzoom: 7,
        maxzoom: 23,
        layout: {
            visibility: undefined,
        },
        paint: {
            "fill-color": {
                r: 0.41568627450980394,
                g: 0.5843137254901961,
                b: 0.796078431372549,
                a: 1,
            },
            "fill-opacity": 0.4,
            "fill-outline-color": {
                r: 0.13725490196078433,
                g: 0.13725490196078433,
                b: 0.13725490196078433,
                a: 1,
            },
        },
    },
    source: "evacuation_zone",
    sourceLayer: "evacuation_zone",
    state: {
    },
}