import { Grid } from '@mui/material'
import React from 'react'

import { Card } from '~/layouts/Card/Card'
import { theme } from '~/theme'

function CardItem({ children }) {
    return (
        <Card
            backgroundColor='grey'
            hasShadow={false}
            sx={{
                flex: 1,
                textAlign: 'left',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'left',
                paddingLeft: theme.spacing(4),
                justifyContent: 'center',
                gap: 1,
                height: '100%'
            }}
        >
            {children}
        </Card>
    )
}

export default CardItem