import { Box, Typography } from '@mui/material';

import Icon from '~/components/Icon';
import { theme } from '~/theme';
import { PropsWithSx } from '~/utils';

import { I18n } from "../../i18n/i18n";
import { Color } from '~/constants';


interface Props {
    zoneId: number | string,
    location: string,
    currentLocaiton?: boolean
}

export const SelectedLocation = ({
    zoneId,
    location,
    currentLocaiton
}: PropsWithSx<Props>) => {
    return (
        <Box
            sx={{ minHeight: 100 }}
        >
            <Box sx={{ display: "flex", flexDirection: "column", gap: theme.spacing(1) }}>
                <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "flex-start", gap: theme.spacing(1) }}>
                    <Icon name='LocationShow' size={25} color={Color.DarkBlue}></Icon>
                    <Typography variant="body1" sx={{ fontWeight: "bold" }} textTransform='uppercase'>
                        {currentLocaiton ? I18n.t('myZones.myCurrentLocation') : I18n.t('myZones.selectedLocation')}
                    </Typography>
                </Box>
                <Typography
                    variant="body1"
                    sx={{ marginLeft: ".3em" }}
                >
                    {location}
                </Typography>
                {
                    zoneId &&
                    <Typography
                        variant="body1"
                        sx={{ marginLeft: ".3em" }}
                    >
                        <b>{I18n.t('myZones.zoneId')}</b>: {zoneId}
                    </Typography>
                }
            </Box>
        </Box>
    );
};