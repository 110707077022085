import { Box, Typography } from '@mui/material';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

import Icon from '~/components/Icon';
import { DataSourceIcon } from '~/components/Icon/DataSourceIcon';
import { theme } from '~/theme';

import DataSources from './DataSources';
import DisclaimerModal from './DisclaimerModal';
import { AreaIcon } from '../../components/Icon/AreaIcon';
import { CalendarIcon } from '../../components/Icon/CalendarIcon';
import { FireIcon } from '../../components/Icon/FireIcon';
import { MapPreview } from '../../components/MapPreview';
import { Loader } from '../../components/UI';
import { HazardDetailCard } from '../../containers/Hazards/HazardDetailCard';
import { I18n } from '../../i18n';
import { Card } from '../../layouts/Card';
import { Page } from '../../layouts/Page';
import { useFetchHazardDetailsQuery } from '../../redux-rtk';
import {
  AppActions,
  LocationSelectionType,
} from '../../redux-rtk/slices/appSlice';

export const HazardDetails = () => {
  const dispatch = useDispatch();

  const { id: hazardId } = useParams();

  const { data: hazard, isLoading } = useFetchHazardDetailsQuery(hazardId);
  const [modalType, setModalType] = useState<string | null>(null);

  useEffect(() => {
    if (hazard) {
      dispatch(
        AppActions.selectLocation({
          id: hazard.id,
          details: hazard,
          type: LocationSelectionType.Hazard,
          position: [hazard.latitude, hazard.longitude],
        }),
      );
    }
  }, [hazard]);

  const handleDisclaimerIconClick = (title: string) => {
    setModalType(title);
  };

  const handleModalClose = () => {
    setModalType(null);
  };

  return (
    <Page hasBackButton onBackPressNavigateTo="/hazards">
      {isLoading || !hazard ? (
        <Loader />
      ) : (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
          <Typography variant="h1" sx={theme => ({ ...theme.typography.h2 })}>
            {hazard.name}
          </Typography>

          <Typography variant="caption">
            {I18n.t('fireDetails.updated')}:{' '}
            {dayjs(hazard.updatedDate).format('hh:mma MM/DD/YYYY')}
          </Typography>

          <HazardDetailCard
            icon={<FireIcon role="img" aria-hidden="true" />}
            title={I18n.t('fireDetails.contained')}
            value={
              hazard.percentContained ? `${hazard.percentContained}%` : 'N/A'
            }
            showDisclaimerIcon={true}
            onDisclaimerIconClick={handleDisclaimerIconClick}
          />

          <HazardDetailCard
            icon={<CalendarIcon role="img" aria-hidden="true" />}
            title={I18n.t('fireDetails.startDate')}
            value={dayjs(hazard.startedDate).format('MM/DD/YYYY')}
          />

          <HazardDetailCard
            icon={<AreaIcon role="img" aria-hidden="true" />}
            title={I18n.t('fireDetails.acreage')}
            value={hazard.acreage.toLocaleString()}
          />

          <HazardDetailCard
            icon={<DataSourceIcon role="img" aria-hidden="true" />}
            title={I18n.t('fireDetails.dataSources')}
            value={
              hazard?.dataSources?.length === 1 && (
                <Typography variant="body1">{hazard.dataSources[0]}</Typography>
              )
            }
            showDisclaimerIcon={true}
            onDisclaimerIconClick={handleDisclaimerIconClick}
          >
            {hazard?.dataSources?.length > 1 && (
              <DataSources dataSources={hazard?.dataSources} />
            )}
          </HazardDetailCard>

          {hazard.affectedCounties?.length > 0 && (
            <HazardDetailCard
              icon={
                <Icon
                  name="County"
                  size={24}
                  color={theme.palette.common.black}
                />
              }
              title={I18n.t('fireDetails.countiesAffected')}
            >
              <Box sx={{ display: 'flex', gap: 2, flexDirection: 'column' }}>
                {hazard.affectedCounties?.map(countyName => (
                  <Card
                    padding={1.5}
                    key={countyName}
                    hasShadow={false}
                    sx={{ display: 'flex', gap: 2 }}
                  >
                    {`${countyName}, ${hazard.state}`}
                  </Card>
                ))}
              </Box>
            </HazardDetailCard>
          )}
          <DisclaimerModal
            modalType={modalType}
            handleModalClose={handleModalClose}
          />
          <MapPreview />
        </Box>
      )}
    </Page>
  );
};
