import * as turf from '@turf/turf';
import { convertFromRaw } from 'draft-js';
import { stateToHTML } from 'draft-js-export-html';
import { Position } from 'geojson';
import { LngLat, LngLatBounds, MapGeoJSONFeature } from 'maplibre-gl';
import { MapRef } from 'react-map-gl/maplibre';
import {
  ArrivalPointId,
  FireId,
  getPointOfInterestType,
  PointFeatureSourceId,
  TrafficControlPointId,
  ZoneId,
} from '~/components/Map/config';
import { ArrivalPoint, RoadClosure } from '~/components/Map/types';
import { LocationSelection, LocationSelectionType } from '~/redux-rtk/slices/appSlice';

const geoserverCreds = JSON.parse(process.env.REACT_APP_GEOSERVER_CREDS);

const geoserver = {
  WMTS: process.env.REACT_APP_GEOSERVER_WMTS ?? '',
  WFS: process.env.REACT_APP_GEOSERVER_WFS ?? '',
  Creds: btoa(geoserverCreds.username + ':' + geoserverCreds.password) ?? '',
};

const maplibre = {
  StreetUrl:
    `${process.env.REACT_APP_ESRI_MAP_URL}${process.env.REACT_APP_ESRI_APIKEY}` ??
    '',
};

const getWMTS = (
  layer: string,
  gridSet = 'EPSG:900913',
  tileMatrix = gridSet,
): string => {
  return (
    `${geoserver.WMTS}` +
    '?REQUEST=GetTile&SERVICE=WMTS' +
    '&VERSION=1.0.0&LAYER=' +
    'zonehavenv2' +
    ':' +
    layer +
    `&STYLE=&TILEMATRIX=${tileMatrix}:{z}` +
    `&TILEMATRIXSET=${gridSet}&FORMAT=application/vnd.mapbox-vector-tile` +
    '&TILECOL={x}&TILEROW={y}&caheVersion=' +
    Math.round(new Date().getTime() / 1000)
  );
};

const getLatLngCenterFromBbox = bbox => {
  const getSwAndNeFromBbox = bbox => {
    const parenthesesContentRegEx = /\(([^)]+)\)/;
    const coords = bbox.match(parenthesesContentRegEx)[1];
    const [coords1, coords2] = coords.split(',');
    const [lat1, lng1] = coords1.split(' ');
    const lngLatSW = new LngLat(lat1, lng1);
    const [lat2, lng2] = coords2.split(' ');
    const lngLatNE = new LngLat(lat2, lng2);
    return { sw: lngLatSW, ne: lngLatNE };
  };

  const { sw, ne } = getSwAndNeFromBbox(bbox);
  const bounds = new LngLatBounds(sw, ne);
  const center = bounds.getCenter();
  return [center.lat, center.lng] as Position;
};

const getZoneIdFromZoneName = (id: string) => {
  if (!id) {
    return '';
  }
  const splitted = id.split('-');
  return splitted.slice(3).join('-').trim();
};

const getZoneCenter = (zoneId: number, map: MapRef) => {
  const feature = map.queryRenderedFeatures(null, {
    layers: ['evacuation_zone'],
    filter: ['==', 'zone_id', zoneId],
  })[0];

  if (!feature) {
    return null;
  }

  return turf.centerOfMass(feature).geometry.coordinates.reverse() as Position;
};

const getApOrTcpFromMapData = (
  mapData: MapGeoJSONFeature,
  prevData: { id: string },
  sourceLayer?: string,
): RoadClosure | ArrivalPoint => ({
  ...prevData,
  type: getPointOfInterestType(mapData as MapGeoJSONFeature, sourceLayer),
  address:
    mapData.properties.tcp_name ?? mapData.properties.arrival_point_address,
  name: mapData.properties.arrival_point_name,
  description: mapData.properties.tcp_note
    ? stateToHTML(convertFromRaw(JSON.parse(mapData.properties.tcp_note)))
    : '',
});

// Given a location type based on a selection location, return the map's source layer that
// corresponds to it
const getSourceFromLocationType = (
  locationType: LocationSelectionType,
): [
  typeof FireId | typeof ZoneId | typeof PointFeatureSourceId,
  (
    | typeof FireId
    | typeof ZoneId
    | typeof TrafficControlPointId
    | typeof ArrivalPointId
  ),
] => {
  switch (locationType) {
    case LocationSelectionType.Zone:
      return [ZoneId, ZoneId];
    case LocationSelectionType.Hazard:
      return [FireId, FireId];
    case LocationSelectionType.RoadClosure:
    case LocationSelectionType.TrafficControlPoint:
      return [PointFeatureSourceId, TrafficControlPointId];
    case LocationSelectionType.RallyPoint:
    case LocationSelectionType.ArrivalPoint:
    case LocationSelectionType.AnimalShelter:
    case LocationSelectionType.EvacuationCenter:
    case LocationSelectionType.OnRamp:
    case LocationSelectionType.ResourceCenter:
    case LocationSelectionType.Shelter:
      return [PointFeatureSourceId, ArrivalPointId];
    case LocationSelectionType.Position:
    default:
      // Not mapped to a specific map layer
      return [null, null];
  }
};

const setFeatureStateForPoint = (
  selectedPoint: ArrivalPoint | RoadClosure,
  mapRef: MapRef,
  selected = true,
) => {
  if (selectedPoint) {
    const [source, sourceLayer] = getSourceFromLocationType(selectedPoint.type);
    mapRef.setFeatureState(
      {
        id: selectedPoint.id,
        source,
        sourceLayer,
      },
      { selected },
    );
  }
};

const checkTopFeatureIsAlert = (feature: LocationSelection) => {
  return feature.type === LocationSelectionType.Alert || feature.type === LocationSelectionType.Zone;
}

export {
  geoserver,
  maplibre,
  getWMTS,
  getLatLngCenterFromBbox,
  getZoneIdFromZoneName,
  getZoneCenter,
  getApOrTcpFromMapData,
  setFeatureStateForPoint,
  checkTopFeatureIsAlert
};
