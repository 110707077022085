import { Box, Typography, useTheme } from '@mui/material';
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import SearchAddressOrZone from '~/components/SearchInput/index';
import TabsComponent from '~/components/TabsComponent';
import { Color } from '~/constants';
import { useIsMobileDevice } from '~/hooks';
import { I18n } from '~/i18n';
import { useAppDispatch, useAppSelector } from '~/redux-rtk/hooks';
import {
  AlertsSortMethod,
  AlertStatusType,
  AppActions,
} from '~/redux-rtk/slices/appSlice';
import { paramsToObject } from '~/utils/params';

import { sampleCardData } from './mockData';
import TabPanelActive from './TabPanelActive';
import TabPanelExpired from './TabPanelExpired';
import { AlertCardData, AlertListParams, SearchParameters } from './types';

// eslint-disable-next-line import/order

const tabData = [
  { id: AlertStatusType.Active, label: I18n.t('alertsList.tabLabelActive') },
  { id: AlertStatusType.Expired, label: I18n.t('alertsList.tabLabelExpired') },
];

const AlertsList = () => {
  const theme = useTheme();
  const isMobile = useIsMobileDevice();
  const [searchParams, setSearchParams] = useSearchParams();
  const [alertList, setAlertList] = useState<AlertCardData[]>();

  const sortMethod = useAppSelector(state => state.app.data.selectedAlertSort);
  const alertCritical = useAppSelector(state => state.app.data.alertCritical);
  const alertSearchTerm = useAppSelector(
    state => state.app.data.alertSearchTerm,
  );
  const alertStatus = useAppSelector(state => state.app.data.alertStatus);
  const dispatch = useAppDispatch();

  useEffect(() => {
    // The first time we load the 'Alerts List' page, update the app's "selected sort, critical, search" based on query
    // params, if applicable
    const querySortMethod = searchParams.get('sort') as AlertsSortMethod;
    const queryAlertCritical = searchParams.get('critical');
    const queryAlertSearch = searchParams.get('search');
    const queryAlertStatus = searchParams.get('status') as AlertStatusType;
    if (Object.values(AlertsSortMethod).includes(querySortMethod)) {
      dispatch(AppActions.selectAlertSort(querySortMethod));
    }

    if (Object.values(AlertStatusType).includes(queryAlertStatus)) {
      dispatch(AppActions.changeAlertStatus(queryAlertStatus));
    }

    if (queryAlertSearch?.length >= 0) {
      dispatch(AppActions.updateAlertSearchTerm(queryAlertSearch));
    }

    if (queryAlertCritical === 'true') {
      dispatch(AppActions.changeAlertCritical(true));
    }
  }, []);

  // mock api call will be removed once the actual api is available
  const mockApiCall = (params: AlertListParams) => {
    let alertListResponse: AlertCardData[] = sampleCardData;
    if (!params.sort && !params.filters)
      return Promise.resolve(alertListResponse);
    if (params?.filters?.status === 'active') {
      alertListResponse = alertListResponse.filter(
        alert => alert.status === 'active',
      );
    } else {
      alertListResponse = alertListResponse.filter(
        alert => alert.status === 'expired',
      );
    }
    if (params?.filters?.status === 'active' && params.filters?.criticalOnly) {
      alertListResponse = alertListResponse.filter(alert => alert.critical);
    }
    return Promise.resolve(alertListResponse);
  };

  const getAlertLists = async (params: AlertListParams) => {
    // just to mock api call, will be changed once api is available
    const alertListsData = await mockApiCall(params);
    setAlertList(alertListsData);
  };

  useEffect(() => {
    const searchParameters: SearchParameters = {
      ...paramsToObject(searchParams),
      critical: alertCritical.toString(),
      status: alertStatus,
    };
    if (sortMethod) {
      searchParameters.sort = sortMethod;
    }
    if (alertSearchTerm?.length >= 0) {
      searchParameters.search = alertSearchTerm;
    }
    setSearchParams(searchParameters);
    getAlertLists({
      filters: {
        criticalOnly: alertCritical,
        status: alertStatus,
      },
    });
  }, [sortMethod, alertCritical, alertSearchTerm, alertStatus]);

  return (
    <Box
      sx={{
        padding: `${isMobile ? '16px' : '32px'}`,
        height: '100%',
        width: '100%',
        overflow: 'scroll',
      }}
    >
      <Box>
        <Typography
          variant="h1"
          sx={{ mb: 1, color: theme.palette.secondary.main }}
        >
          {I18n.t('alertsList.title')}
        </Typography>
        <Box
          sx={{
            mb: 1,
            width: '75%',
            color: Color.NeutralGrey,
          }}
        >
          <Typography variant="body1">
            {I18n.t('alertsList.subTitle')}
          </Typography>
        </Box>
      </Box>
      <Box
        sx={{
          mt: theme.spacing(2),
          maxWidth: '300px',
        }}
      >
        <SearchAddressOrZone
          placeHolderText={I18n.t('inputsPlaceholder.searchAlert')}
          onInputSelect={(value, action) => {
            console.log('value', value);
          }}
          onInputValueChange={(value, action) => {
            console.log(value);
          }}
        />
      </Box>
      <TabsComponent
        tabsData={tabData}
        onTabChange={(event, id) => {
          dispatch(AppActions.changeAlertStatus(id as AlertStatusType));
        }}
        activeTab={alertStatus}
      >
        <TabPanelActive
          selectedTab={AlertStatusType.Active}
          activeAlerts={alertList}
        />
        <TabPanelExpired
          selectedTab={AlertStatusType.Expired}
          expiredAlerts={alertList}
        />
      </TabsComponent>
    </Box>
  );
};

export default AlertsList;
