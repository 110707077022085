import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import en from './locales/en.json';
import es from './locales/es.json';

i18n
  .use(initReactI18next) // Integrates with React
  .init({
    resources: {
      en: { translation: en },
      es: { translation: es },
      // Add other languages here
    },
    fallbackLng: 'en', // Fallback language
    debug: false,

    interpolation: {
      escapeValue: false,
    },
  });

export { i18n as I18n };
