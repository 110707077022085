import { Box, Typography } from '@mui/material';
import { useEffect } from 'react';
import { useLocalStorage, useReadLocalStorage } from 'usehooks-ts';
import { ReactComponent as NoLocationFoundIcon } from 'assets/icons/no-location-found.svg';
import SearchZoneInput from 'containers/SearchZoneInput';
import { isFollowZoneEnabled, isMobileDownloadEnabled } from 'flags';
import { useIsMobileDevice } from 'hooks';
import { I18n } from 'i18n';
import { Card } from 'layouts/Card';
import { Content } from 'layouts/Content';
import { Page } from 'layouts/Page';
import {
  useAppDispatch,
  useAppSelector,
  useFetchZoneDetailsWithCoordsQuery,
} from 'redux-rtk';
import {
  AppActions,
  AppSetupSteps,
  LocationSelectionType,
} from 'redux-rtk/slices/appSlice';
import {
  LocalStorageFollowedZone,
  LocalStorageKeys,
  LocationOrigin,
} from 'types';
import { parseBBOX } from 'utils';
import { Divider } from '~/components/Divider';
import { GPSErrorCard } from '~/components/GPSErrorCard';
import GPSPermissionCard from '~/components/GPSPermissionCard';
import { MapSearchIcon } from '~/components/Icon/MapSearchIcon';
import { ZoneCard } from '~/components/Map/MapCards/ZoneCard';
import { MapPreview } from '~/components/MapPreview';
import { DownloadAppCTA } from '~/components/Marketing/DownloadAppCTA';
import { Loader } from '~/components/UI';

import { AccuracyCard } from './AccuracyCard';
import { InaccuracyInMetersForWarning } from '../../constants';

export const Search = () => {
  const dispatch = useAppDispatch();

  const preferredOrigin = useReadLocalStorage<LocationOrigin | null>(
    LocalStorageKeys.PreferredOrigin,
  );

  const isMobile = useIsMobileDevice();

  const [followedZones] = useLocalStorage<LocalStorageFollowedZone[]>(
    LocalStorageKeys.FollowedZones,
    [],
  );

  const userLocation = useAppSelector(state => state.user.data.currentLocation);
  const visibleOnboardingModal = useAppSelector(state => state.app.onboardingModalIsVisible);
  const completedAppSetupSteps = useAppSelector(
    state => state.app.data.completedAppSetupSteps,
  );
  const selectedLocation = useAppSelector(
    state => state.app.data.selectedLocation,
  );

  // Fetch zone details, but only if we have a user location that came from the browser
  const isAbleToMakeZoneDetailsQuery =
    userLocation.coordinates && userLocation.origin === LocationOrigin.Browser;

  const {
    data: userZoneDetails,
    isFetching,
    isError,
  } = useFetchZoneDetailsWithCoordsQuery(userLocation.coordinates, {
    skip: !isAbleToMakeZoneDetailsQuery,
  });

  useEffect(() => {
    // if we have the users zone info, select zone as the initial location
    // note: on mobile we always want to come back to the users zone
    if (
      userZoneDetails?.zone &&
      ((userLocation.origin === LocationOrigin.Browser &&
        !completedAppSetupSteps.includes(
          AppSetupSteps.SetInitialLocationSelection,
        )) ||
        (isMobile && selectedLocation?.id !== userZoneDetails?.zone.identifier))
    ) {
      const {
        latitude,
        longitude,
        identifier,
        bbox: bboxString,
      } = userZoneDetails.zone;

      dispatch(
        AppActions.setInitialLocation({
          position: [latitude, longitude],
          type: LocationSelectionType.Zone,
          details: userZoneDetails.zone,
          id: identifier,
          bbox: parseBBOX(bboxString),
          settings: {
            hasPopup: false,
            hasFlyToAnimation: false,
          },
        }),
      );
    }
  }, [
    userZoneDetails?.zone,
    isMobile,
    selectedLocation,
    userLocation,
    completedAppSetupSteps,
    dispatch,
  ]);

  useEffect(() => {
    // if we manage to get users coords via geotargetly but have no zone info
    // pan map to users approximate location
    if (
      userLocation.origin === LocationOrigin.Geotargetly &&
      !completedAppSetupSteps.includes(
        AppSetupSteps.SetInitialLocationSelection,
      )
    ) {
      dispatch(
        AppActions.setInitialLocation({
          position: userLocation.coordinates,
          type: LocationSelectionType.Position,
        }),
      );
    }
  }, [dispatch, userLocation, completedAppSetupSteps]);

  return (
    <Page title={I18n.t('search.title')} subtitle={I18n.t('search.subtitle')}>
      <SearchZoneInput />

      <Content sx={{ position: 'relative' }}>
        <Divider label={I18n.t('search.myCurrentLocation')} />

        {(!preferredOrigin ||
        userLocation.origin === LocationOrigin.Pending ||
        isFetching )&& !visibleOnboardingModal ? (
          <Loader />
        ) : userZoneDetails ? (
          <>
            <ZoneCard
              isUserZone
              isFollowButtonVisible={isFollowZoneEnabled}
              zoneId={userZoneDetails.zone.identifier}
            />
            <AccuracyCard
              isAccurate={
                userLocation.accuracyInMeters < InaccuracyInMetersForWarning
              }
            />
          </>
        ) : preferredOrigin === LocationOrigin.Geotargetly ? (
          <>
            <GPSPermissionCard />

            <Typography
              variant="body2"
              sx={{
                gap: 1,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <MapSearchIcon role="img" aria-hidden="true" />
              {I18n.t('search.selectAZone')}
            </Typography>
          </>
        ) : // this case happens when the user allowed browser gps but
        // for some reason we failed to get the coords (think timeout, etc)
        preferredOrigin === LocationOrigin.Browser &&
          userLocation.origin === LocationOrigin.Geotargetly ? (
          <GPSErrorCard />
        ) : isError || !userZoneDetails ? (
          <>
            <Card>
              <Typography
                variant="h3"
                sx={{
                  gap: 1.5,
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <NoLocationFoundIcon width="36px" height="36px" role="img" aria-hidden="true" />
                {I18n.t('search.noZoneFound')}
              </Typography>

              <Typography variant="body1" sx={{ mt: 2 }}>
                {I18n.t('search.notFound', { appName: I18n.t("common.appName") })}
              </Typography>
            </Card>

            {userLocation?.accuracyInMeters && (
              <AccuracyCard
                isAccurate={
                  userLocation.accuracyInMeters < InaccuracyInMetersForWarning
                }
              />
            )}
          </>
        ) : null}

        {userLocation.coordinates && <MapPreview />}

        {followedZones?.length > 0 && (
          <>
            <Divider label={I18n.t('search.myFollowedZones')} sx={{ mt: 3 }} />

            {followedZones.map(el => (
              <ZoneCard
                key={el.zoneId}
                alias={el.alias}
                zoneId={el.zoneId}
                isFollowButtonVisible={isFollowZoneEnabled}
              />
            ))}
          </>
        )}
      </Content>
      {isMobileDownloadEnabled && (
        <Box sx={{backgroundColor: "grey.50", p:2, mx: -3 }}>
          <Card
            hasShadow={false}
            sx={{
              textAlign: 'center',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <DownloadAppCTA />
          </Card>
        </Box>)}
    </Page>
  );
};
